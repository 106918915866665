import React, { useEffect, useRef } from "react";
import Lottie from "lottie-react";
import Donate from "../../assets/AnimationIcon/Donate.gif";
import Projects from "../../assets/AnimationIcon/new-one.gif";
import BecomeMember from "../../assets/AnimationIcon/BecomeaMember.json";
import box1 from "../../assets/Headers/SVG/Asset_1.svg";
import box2 from "../../assets/Headers/SVG/Asset_2.svg";
import box3 from "../../assets/Headers/SVG/Asset_3.svg";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Header = () => {
  const BecomeaMemberLottie = useRef();
  const GifRef = useRef();
  const ProjectsLottie = useRef();
  const DonateLottie = useRef();
  useEffect(() => {
    if (BecomeaMemberLottie.current) {
      BecomeaMemberLottie.current.goToAndStop(100, true);
    }
    if (ProjectsLottie.current) {
      ProjectsLottie.current.goToAndStop(120, true);
    }
    if (DonateLottie.current) {
      DonateLottie.current.goToAndStop(120, true);
    }
  });
  return (
    <div className="">
      <div
        id="header"
        className="relative w-full h-[37rem] overflow-hidden flex bg-bg items-center text-greenshade "
      >
        <img
          alt="gif"
          className="h-[90%] hidden sm:block"
          onMouseEnter={() => {
            if (GifRef.current) {
            }
          }}
          onMouseLeave={() => {
            if (GifRef.current) {
              GifRef.current.currentTime = 0;
            }
          }}
          ref={GifRef}
          loop={true}
          src="videos/constantionGif.gif"
        />
        <motion.div className="flex m-10 z-10 flex-col ">
          <div className=" relative">
            <motion.img
              initial={{
                x: 0,
                y: -100,
                opacity: 0,
              }}
              whileInView={{
                x: 0,
                y: 0,
                opacity: 1,
                transition: {
                  duration: 0.4,
                  type: "easy",
                },
              }}
              className="mb-10"
              alt="box"
              src={box1}
            />
            <motion.img
              initial={{
                x: 100,
                y: 100,
                opacity: 0,
              }}
              whileInView={{
                x: 0,
                y: 0,
                opacity: 1,
                transition: {
                  duration: 0.7,
                  type: "easy",
                },
              }}
              className=" absolute bottom-[15%] lg:left-[8%] md:left-[11%] left-[11%] -z-10 h-[80%] "
              alt="box"
              src={box3}
            />
            <motion.img
              initial={{
                x: -100,
                y: -100,
                opacity: 0,
              }}
              whileInView={{
                x: 0,
                y: 0,
                opacity: 1,
                transition: {
                  duration: 0.7,
                  type: "easy",
                },
              }}
              className=" absolute bottom-[-20%] lg:left-[17%] md:left-[20%] left-[20%] -z-10 h-[80%] "
              alt="box"
              src={box3}
            />
            <motion.img
              initial={{
                x: -100,
                y: 100,
                opacity: 0,
              }}
              whileInView={{
                x: 0,
                y: 0,
                opacity: 1,
                transition: {
                  duration: 0.7,
                  type: "easy",
                },
              }}
              className="absolute -z-10 lg:bottom-[-40%] lg:left-[-0%] lg:h-[80%] md:bottom-[-40%] md:left-[-0%] md:h-[80%] bottom-[-40%] left-[5%]  h-[80%]"
              alt="box"
              src={box2}
            />
          </div>

          <div className="flex gap-10">
            <div className=" relative w-[40%]"></div>
            <motion.p
              initial={{
                x: 0,
                y: 100,
                opacity: 0,
              }}
              whileInView={{
                x: 0,
                y: 0,
                opacity: 1,
                transition: {
                  duration: 0.4,
                  type: "easy",
                },
              }}
              className=" text-[1rem] w-[60%]"
            >
              Promoting and supporting local cultures and morals in Africa and
              fostering humanitarian cooperation
            </motion.p>
          </div>
        </motion.div>
      </div>
      <div className=" -translate-y-10">
        <div className="md:w-3/4 w-11/12 m-auto grid grid-cols-1 md:grid-cols-3 gap-10 mb-20  ">
          <motion.div
            initial={{
              y: 250,
              opacity: 0,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              scale: 1,
              transition: {
                duration: 0.8,
                delay: 0,
              },
            }}
            viewport={{ once: true }}
            className=" bg-gradient-radial from-lightgreen  to-primary p-6 pt-10 rounded-[3rem]  md:rounded-[1rem] text-white h-full flex flex-col items-center gap-2 md:gap-3 text-center"
          >
            <div
              onMouseEnter={() => {
                BecomeaMemberLottie.current.goToAndPlay(4, true);
              }}
              onMouseLeave={() => {
                BecomeaMemberLottie.current.goToAndStop(100, true);
              }}
              className="md:h-32 md:w-32  w-20 h-20 bg-white rounded-full flex justify-center items-center cursor-pointer "
            >
              <Lottie
                animationData={BecomeMember}
                loop={true}
                autoplay={false}
                lottieRef={BecomeaMemberLottie}
              />
            </div>
            <p className=" text-[1.2rem] md:text-[1.9rem]">
              Become An Ambassador
            </p>
            <p className=" text-[0.8rem] md:text-[1rem]  font-sans leading-none mb-4">
              Join your hand with us to build a better future. Together We
              Change.
            </p>
            <Link to={"/becomeambasador"} onClick={() => window.scrollTo(0, 0)}>
              <div className="font-sans bg-buttongreen rounded-[2rem] px-3 py-2 cursor-pointer">
                Read More
              </div>
            </Link>
          </motion.div>
          <motion.div
            initial={{
              y: 250,
              opacity: 0,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              scale: 1,
              transition: {
                duration: 0.8,
                delay: 0,
              },
            }}
            viewport={{ once: true }}
            className=" bg-gradient-radial from-lightgreen  to-primary p-6 pt-10 rounded-[3rem]  md:rounded-[1rem] text-white h-full flex flex-col items-center gap-2 md:gap-3 text-center"
          >
            <div
              // onMouseEnter={() => {
              //   ProjectsLottie.current.goToAndPlay(4, true);
              // }}
              // onMouseLeave={() => {
              //   ProjectsLottie.current.goToAndStop(100, true);
              // }}
              className="md:h-32 md:w-32 h-20 w-20  bg-white rounded-full flex justify-center items-center cursor-pointer "
            >
              {/* <Lottie
                animationData={Projects}
                loop={true}
                autoplay={false}
                lottieRef={ProjectsLottie}
              /> */}
              <img
                src={Projects}
                className=" rounded-full"
              />
            </div>
            <p className=" text-[1.2rem] md:text-[1.9rem]">Projects</p>
            <p className=" font-sans leading-none mb-4 text-[0.8rem] md:text-[1rem]">
              Get insights in to ongoing, completed and upcoming projects.
            </p>

            <Link to={"/projects"} onClick={() => window.scrollTo(0, 5450)}>
              <div className="font-sans bg-buttongreen rounded-[2rem] px-3 py-2 cursor-pointer">
                Read More
              </div>
            </Link>
          </motion.div>
          <motion.div
            initial={{
              y: 250,
              opacity: 0,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              scale: 1,
              transition: {
                duration: 0.8,
                delay: 0,
              },
            }}
            viewport={{ once: true }}
            className=" bg-gradient-radial from-lightgreen  to-primary p-6 pt-10 rounded-[3rem]  md:rounded-[1rem]  text-white h-full flex flex-col items-center gap-2 md:gap-3 text-center"
          >
            <div
              // onMouseEnter={() => {
              //   DonateLottie.current.goToAndPlay(4, true);
              // }}
              // onMouseLeave={() => {
              //   DonateLottie.current.goToAndStop(100, true);
              // }}
              className="md:h-32 md:w-32 h-20 w-20  bg-white rounded-full flex justify-center items-center "
            >
              {/* <Lottie
                animationData={Donate}
                loop={true}
                autoplay={false}
                lottieRef={DonateLottie}
              /> */}
              <img
                src={Donate}
                className="pl-5 rounded-full"
                />
            </div>
            <p className=" text-[1.2rem] md:text-[1.9rem] ">Donate</p>
            <p className=" font-sans leading-none mb-4 text-[0.8rem] md:text-[1rem]">
              Support marginalized and vulnerable populations in Uganda and all
              of Africa.
            </p>

            <Link to={"/donate"} onClick={() => window.scrollTo(0, 0)}>
              <div className="font-sans bg-buttongreen rounded-[2rem] px-3 py-2 cursor-pointer">
                Read More
              </div>
            </Link>
          </motion.div>
        </div>
        <div className="md:w-4/5 w-11/12 m-auto h-1  bg-black"></div>
      </div>
    </div>
  );
};

export default Header;
