import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

export default function PurposeSlideFromBottom() {
  const [isVisible, setVisible] = useState(false);

  const handleScroll = () => {
    const element = document.querySelector(".slide-from-bottom");
    const positionFromTop = element.getBoundingClientRect().top;
    const windowHeight = window.innerHeight;

    if (positionFromTop - windowHeight <= 0) {
      setVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <motion.div className="ml-[2%] mr-[2%] mt-[2%]">
      <motion.div
        className="slide-from-bottom flex justify-center items-center flex-wrap mb-[40px]"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }}
        transition={{ duration: 2, ease: "easeOut" }}
      >
        <h1 className="w-full text-center text-[2.5rem] text-greenshade font-bold">
          Our Purpose
        </h1>
        <h5 className="w-full text-center text-[1rem] font-bold text-green">
          What we do is not for us but for the world
        </h5>
      </motion.div>
      <motion.div className="slide-from-bottom grid grid-cols-1 gap-4 text-white md:grid-cols-2 lg:grid-cols-4">
        <motion.div
          className="slide-from-bottom bg-green rounded-[10px] pb-8"
          initial={{
            y: 150,
            opacity: 0,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            scale: 1,
            transition: {
              duration: 0.8,
              delay: 0,
            },
          }}
        >
          <h1 className="mb-4 mt-2 text-center md:mb-8 md:mt-4 text-[2rem]">
            Quality Education
          </h1>
          <p className="px-4 md:px-6 font-auto font-sans">
          The African Constantine Foundation strives to uplift education in Africa.
           Through partnerships and initiatives, 
           they enhance educational quality for a brighter,
           equitable future.
          </p>
        </motion.div>
        <motion.div
          className="slide-from-bottom bg-green rounded-[10px] pb-8"
          initial={{
            y: 150,
            opacity: 0,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            scale: 1,
            transition: {
              duration: 0.8,
              delay: 0.5,
            },
          }}
        >
          <h1 className="mb-4 mt-2 text-center md:mb-8 md:mt-4 text-[2rem]">
            Agriculture
          </h1>
          <p className="px-4 md:px-6 font-sans">
           committed to bolstering the agricultural sector. 
          Through innovative initiatives and partnerships, 
          they aim to enhance sustainable farming practices and ensure food security for the nation's prosperity.
          </p>
        </motion.div>
        <motion.div
          className="slide-from-bottom bg-green rounded-[10px] pb-8"
          initial={{
            y: 150,
            opacity: 0,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            scale: 1,
            transition: {
              duration: 0.8,
              delay: 1,
            },
          }}
        >
          <h1 className="mb-4 mt-2 text-center md:mb-8 md:mt-4 text-[2rem]">
            Infrastructure
          </h1>
          <p className="px-4 md:px-6 font-sans">
          actively engaged in improving infrastructure. 
          Through various projects and partnerships, they work to enhance the nation's transportation, 
          utilities, and public facilities to foster economic growth and development.
          </p>
        </motion.div>
        <motion.div
          className="slide-from-bottom bg-green rounded-[10px] pb-8"
          initial={{
            y: 150,
            opacity: 0,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            scale: 1,
            transition: {
              duration: 0.8,
              delay: 1.5,
            },
          }}
        >
          <h1 className="mb-4 mt-2 text-center md:mb-8 md:mt-4 text-[2rem]">
            Quality Health Care
          </h1>
          <p className="px-4 md:px-6 font-sans">
           focused on elevating the quality of education. Through diverse initiatives and collaborations, 
           they aim to enhance educational standards, provide resources, and create a brighter future for the nation's youth.
          </p>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}
