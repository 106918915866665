import axios from "axios";
import { put, takeLatest } from "redux-saga/effects";
import { updateBlogAction, updateBlogFailureAction, updateBlogSuccessAction } from "../slices/slice";

function* updateBlogSaga({id, payload}) {
    try {
        yield put(updateBlogAction());
        const response = yield axios.patch("");
        yield put(updateBlogSuccessAction(response.data));
    } catch(error) {
        yield put(updateBlogFailureAction(error));
    }
}

export function* watchUpdateBlogSaga() {
    yield takeLatest('UPDATE_SONG_BY_ID', updateBlogSaga)
}