import React, { useEffect, useState } from "react";
import axios from "axios";
import EventHeader from "../../assets/Constantine Events Header.png";
import FoundationInfo from "./FoundationInfo";
import EventOrganizer from "./EventOrganizer";
import JoinUsBanner from "./JoinUsBanner";
import UpcomingEvent from "./UpcomingEvent";
import LatestEvent from "./LatestEvent";

function Event() {
  const [Data, setData] = useState(null);
  
  const url = process.env.REACT_APP_API_URL + `/events?populate=*`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios({
          method: "get",
          url: url,
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          },
        });
        setData(data?.data);
      } catch (error) {
        console.log("Error fetching event data", error);
      }
    };

    fetchData();
  }, [url]);

  return (
    <div>
      {/* Header Image */}
      <div
        className="relative flex lg:h-[55rem] md:h-[35rem] h-[18rem] w-full overflow-hidden items-end"
        id="header"
      >
        <img
          src={EventHeader}
          alt="header"
          className="absolute inset-0 -z-10 w-full object-cover object-right md:object-center"
        />
      </div>

      {/* Foundation Information Section */}
      <FoundationInfo />

      {/* Event Organizer Section */}
      <EventOrganizer />

      {/* Upcoming Events Section */}
      <div className="mt-4">
        <UpcomingEvent Data={Data} />
      </div>

      {/* Join Us Banner Section */}
      <JoinUsBanner />

      {/* Latest Events Section */}
      <div className="mt-4">
        <LatestEvent Data={Data} />
      </div>

      {/* Bottom Margin */}
      <div className="mt-12"></div>
    </div>
  );
}

export default Event;
