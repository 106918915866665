import React from "react";
import Header from "./Header";
import Donation from "../../components/GetInTouch";
import Form from "./Form";
import Foll from "./FollowUs";
import FollowUs from "./follow";

const ContactUs = () => {

  return (
    <>
      <Header />
      <Donation />
      <Form />
      {/* <FollowUs /> */}
      <Foll />
    </>
  );
};

export default ContactUs;
