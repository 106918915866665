import * as React from "react";
import { motion } from "framer-motion";
import LinkedIn from "../../assets/social/linkedin.png";
import Twitter from "../../assets/social/twitter X.png";
import Facebook from "../../assets/social/facebook.png";
import Youtube from "../../assets/social/youtube.png";
import Email from "../../assets/social/email.png";
import { useNavigate } from "react-router-dom";

const FollowUs = () => {
  const navigate = useNavigate();

  const iconVariants = {
    hover: {
      scale: 1.5,
      transition: { duration: 0.3 },
    },
  };

  const linkVariants = {
    hover: {
      backgroundColor: "#006837", // Corresponds to bg-gray-300
      color: '#ffffff',
      transition: { duration: 0.3 },
    },
  };

  return (
    <div className="flex flex-col items-center">
      <div className="px-16 pt-7 pb-3.5 max-w-full text-4xl text-darkgreen w-[400px] max-md:px-5">
        Follow Us
      </div>
      <div className="px-16 pt-7 pb-3.5 max-w-full text-3xl text-darkgreen w-[639px] max-md:px-5">
        Get Involved With Our Community
      </div>
      <div className="flex flex-wrap justify-center mt-8 w-full text-darkgreen space-x-5 max-md:space-x-0 max-md:space-y-5">
        <motion.div className="flex items-center" whileHover="hover">
          <motion.img
            src={LinkedIn}
            alt="LinkedIn"
            className="w-16 h-16"
            variants={iconVariants}
          />
          <motion.a
            href="https://www.linkedin.com/company/constantine-foundation/about/?viewAsMember=true"
            className="ml-3 px-4 py-2 bg-zinc-300 rounded-lg"
            variants={linkVariants}
          >
            LinkedIn
          </motion.a>
        </motion.div>
        <motion.div className="flex items-center" whileHover="hover">
          <motion.img
            src={Twitter}
            alt="Twitter"
            className="w-16 h-16"
            variants={iconVariants}
          />
          <motion.a
            href="https://www.twitter.com"
            className="ml-3 px-4 py-0 bg-zinc-300 rounded-lg"
            variants={linkVariants}
          >
            Twitter
          </motion.a>
        </motion.div>
        <motion.div className="flex items-center" whileHover="hover">
          <motion.img
            src={Facebook}
            alt="Facebook"
            className="w-16 h-16"
            variants={iconVariants}
          />
          <motion.a
            href="https://www.facebook.com/profile.php?id=61556522792774"
            className="ml-3 px-4 py-2 bg-zinc-300 rounded-lg"
            variants={linkVariants}
          >
            Facebook
          </motion.a>
        </motion.div>
      </div>
      <div className="flex flex-wrap justify-center mt-8 w-full text-darkgreen space-x-5 max-md:space-x-0 max-md:space-y-5">
        <motion.div className="flex items-center" whileHover="hover">
          <motion.img
            src={Youtube}
            alt="Youtube"
            className="w-16 h-16"
            variants={iconVariants}
          />
          <motion.a
            href="https://www.youtube.com"
            className="ml-3 px-4 py-2 bg-zinc-300 rounded-lg"
            variants={linkVariants}
          >
            Youtube
          </motion.a>
        </motion.div>
        <motion.div className="flex items-center" whileHover="hover">
          <motion.img
            src={Email}
            alt="Email"
            className="w-16 h-16"
            variants={iconVariants}
          />
          <motion.a
            href="mailto:info@constantinefoundation.org"
            className="ml-3 px-4 py-2 bg-zinc-300 rounded-lg"
            variants={linkVariants}
          >
            Email
          </motion.a>
        </motion.div>
      </div>
    </div>
  );
};

export default FollowUs;
