// import React, { useEffect } from "react";
// import axios from "axios";
// import Product from "../components/Product";
// import LoadingBox from "../components/LoadingBox";
// import MessageBox from "../components/MessageBox";
// import { useReducer } from "react";

// const reducer = (state, action) => {
//   switch (action.type) {
//     case "FETCH_REQUEST":
//       return { ...state, loading: true };
//     case "FETCH_SUCCESS":
//       return { ...state, products: action.payload, loading: false };
//     case "FETCH_FAIL":
//       return { ...state, loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };

// function HomeScreen() {
//   const [{ loading, error, products }, dispatch] = useReducer(reducer, {
//     products: [], // Ensure that products is initially an array
//     loading: true,
//     error: "",
//   });

//   useEffect(() => {
//     const fetchData = async () => {
//       dispatch({ type: "FETCH_REQUEST" });
//       try {
//         const result = await axios.get(process.env.REACT_APP_API_URL + "/products?populate=*",
//        { headers: {
//           Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
//         }});
//         dispatch({ type: "FETCH_SUCCESS", payload: result.data });
//       } catch (err) {
//         dispatch({ type: "FETCH_FAIL", payload: err.message });
//       }
//     };
//     fetchData();
//   }, []);

//   return (
//     <div
//       style={{
//         backgroundColor: "#0F6E42",
//         padding: "10px",
//         paddingLeft: "2rem",
//         paddingRight: "2rem",
//       }}
//       className={` rounded-tl-2xl`}
//     >
//       <h1 className={` text-[2.5rem] text-white p-3 `}>Products</h1>
//       <div>
       
//           <div className=" w-full grid sm:grid-cols-2 grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
//             {products?.data?.map((product) => (
//               <div key={product.id} className="w-full  mb-3">
//                 <Product product={product}></Product>
//               </div>
//             ))}
//           </div>
       
//       </div>
//     </div>
//   );
// }

// export default HomeScreen;






import React, { useEffect, useReducer } from "react";
import axios from "axios";
import Product from "../components/Product";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { getError } from "../utils";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, products: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function HomeScreen() {
  const [{ loading, error, products }, dispatch] = useReducer(reducer, {
    products: [],
    loading: true,
    error: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_API_URL}/products?populate=*`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
            },
          }
        );
        dispatch({ type: "FETCH_SUCCESS", payload: result.data });
      } catch (err) {
        dispatch({ type: "FETCH_FAIL", payload: getError(err) });
      }
    };
    fetchData();
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#0F6E42",
        padding: "10px",
        paddingLeft: "2rem",
        paddingRight: "2rem",
      }}
      className="rounded-tl-2xl"
    >
      <h1 className="text-[2.5rem] text-white p-3">Products</h1>
      <div>
        {loading ? (
          <LoadingBox />
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <div className="w-full grid sm:grid-cols-2 grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
            {products.data.map((product) => (
              <div key={product.id} className="w-full mb-3">
                <Product product={product} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default HomeScreen;
