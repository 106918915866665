import * as React from "react";
import { useState } from "react";
//import temporary from "../assets/Constantine Header Coming Soon - replace Other Services.png";
//import asset1 from "../assets/photo_2024-01-24_16-11-33-removebg-preview.png";
import asset1 from "../../src/assets/careers/ByzantineOrthodoxChantingworkshop.png";
import asset2 from "../../src/assets/careers/OrthodoxByzantineMarbleCarving.png";
import asset3 from "../../src/assets/careers/Learningbyzantineorthodoxart.png";
import Modal from "./VolunteerPopup";
import Volunteer from './volunteer';

const Careers = () => {

  const [showFullText1, setShowFullText1] = useState(false);
  const [showFullText2, setShowFullText2] = useState(false);
  const [showFullText3, setShowFullText3] = useState(false);
  const [open, setOpen] = useState(false)

  return (
    <>
      <div
        id="header"
        className="relative w-full h-[37rem] overflow-hidden flex bg-bg items-center text-greenshade "
      >
        <img
          alt="gif"
          src="videos/constantionGif.gif"
          className="h-full"
        />
        <div className="text-center ">
            <h1 className="text-6xl"> 
            Discover Your Journey
            </h1>
            <p className="text-3xl">
            "Fuel Your Passion, Ignite Your Future with Our Foundation!".
            </p>
        </div>
      </div>
      <br/><br/>
      <div className="text-left m-40">
        <div id="first section" >
            <h1 className="text-4xl text-center">
            Be a Catalyst for Change: Shape Africa's Future with Constantine African Foundation.
            </h1><br/><br/>
            <p>
            Start up, Journey with Constantine African Foundation: Lighting the Way with Limited Resources, Amplifying Impact with Your Value!
            As a non-profit organization, Constantine African Foundation is committed to illuminating the continent with the light of 
            Christ and empowering communities to flourish. While we navigate this journey with limited financial resources, we firmly believe 
            in the transformative power of your unique contributions. Join us as we embark on an extraordinary voyage to make a profound impact 
            on the lives of countless individuals across Africa.
  
            </p><br/>
            <u className="text-3xl text-center">
            Our Guiding Beacon: Illuminating the Path to Prosperity
            </u>
            <p>
            Founded with a vision rooted in Christian values, the Constantine African Foundation seeks to initiate projects that foster 
            prosperity and opportunity across Africa. Despite being in the early stages of our journey, our unwavering determination 
            propels us forward, fueled by an unwavering commitment to making a meaningful difference.
            </p><br/>
            <u className="text-3xl text-center">
            Sailing Beyond Financial Constraints: Your Value Amplifies Our Impact
            </u>
            <p>
            While our budget may be modest, we are far from being alone. Your unwavering support and dedication empower us to surmount 
            financial challenges and accomplish remarkable feats. With your invaluable contributions, we can amplify our impact and 
            transform lives across the continent.

            </p><br/>
            <u className="text-3xl text-center">
            A Mission Without Borders: Extending Our Reach to Two African Nations
            </u><br/>
            <p>
            Our mission transcends borders, extending its reach to two distinct African nations. Our team of five dedicated missionaries 
            is already making a tangible difference on the ground, tirelessly working to uplift communities and foster hope. Additionally, 
            we allocate an annual grant to further our mission, ensuring that our impact is felt far and wide.
            </p><br/>
            <u className="text-3xl text-center">
            Career Opportunities: Transforming Individuals and Communities
            </u><br/>
            <p>
            Constantine African Foundation offers career opportunities that are not just professionally fulfilling but also transformative 
            for individuals and communities across Africa. Join us as we embark on this inspiring voyage, guided by the Lord's name and 
            divine blessings. Together, with God's grace, we shall forge a brighter future for all.
            </p><br/>
            <u className="text-3xl text-center">
            Together, We Can Make a Lasting Impact
            </u><br/>
            <p>
            Your contributions, no matter how big or small, have the power to fuel our journey and achieve extraordinary outcomes. We invite 
            you to be a part of this transformative odyssey, spreading the light and love of Christ to all corners of Africa. Join us as we 
            embark on this inspiring voyage, making a lasting impact that transcends borders and reshapes lives.
            With your unwavering support, we can illuminate the path to prosperity and empower countless individuals across Africa 
            to reach their full potential. 

            </p><br/>
            <h2>
            "Together, we can make a world of difference".
            </h2>
        </div>
        <br/><br/><hr/><br/><br/>
        <div id="second section">
            <h1 className=" text-4xl text-center">
            Preparing for a Career with the Constantine African Foundation: 
            </h1>
            <h1 className=" text-4xl text-center">
            Is It Your Next Step? 
            </h1><br/><br/>
            <p>
            Constantine African Foundation presents an attractive salary package paired with outstanding benefits. It's 
            important to note that opportunities in Uganda are exclusively accessible to individuals with Ugandan citizenship.
            <br />
            If you are interested in submitting your resume and a letter of interest or cover letter, have any additional 
            questions or would like to explore other career opportunities with the Constantine African Foundation, please 
            don't hesitate to get in touch with our office at; career@constantinefoundation.org We would be delighted to have a 
            further conversation with you.
            </p>
            <div className="flex justify-center mt-5 text-center">
              {/* <div className="rounded-xl bg-buttongreen md:text-[1rem] text-[0.7rem] px-3 py-1 text-white cursor-pointer hover:scale-105">
                Be Volunteer
              </div> */}
            <main className="App">
            
            <button className="rounded-xl bg-white md:text-2xl text-[0.7rem] px-3 py-1 mt-5 text-green cursor-pointer hover:scale-105" onClick={() => setOpen(true)}>
               Be Volunteer
            </button>
               <Modal open={open} onClose={() => setOpen(false)}>
                 <div className="text-center w-96">
                     <Volunteer />
                   <div className="flex gap-4">
                     <button
                       className="bg-transparent hover:bg-darkgren text-darkgren font-semibold hover:text-white py-2 px-4 border border-darkgren hover:border-transparent rounded"
                       onClick={() => setOpen(false)}
                     >
                       Close
                     </button>
                   </div>
                 </div>
               </Modal>
             </main>
            </div>

            <p>
            If you feel a deep calling to leverage your talents and skills across Africa to bring people closer to Christ and His 
            hurch, consider embarking on a mission journey. Through immersive workshops, you can explore vocations like Byzantine 
            drawing, Byzantine marble carving, and Byzantine chanting.
            <br/>
            Discover the array of missionary opportunities waiting for you as we collaborate with our international mission 
            partners. Take a step towards your mission <a className="hover:underline text-blue-500 text-lg" href="#uganda">click here</a> to explore the available options.
            </p><br/>
            <h1 className="text-3xl text-center">
            Unlock Your Potential,
            </h1>
            <h2  className=" text-2xl text-center">
            Join Us in a Mission of Service, Sharing, and Inspiration.
            </h2>
            <p>
            Are You Ready to Answer God's Divine Calling: 'Go Forth and Spread the Good News Worldwide.
            </p>
            <p>
            Constantine African Foundation invites you to step into a life-changing experience where 
            your skills, passions, and unwavering dedication can make a lasting impact. 
            </p>
            <p>
            Whether you're a seminarian, teacher, priest, professional, skilled tradesperson, engineer, or retiree, 
            there is a place for you to share with us.
            </p>
            <p>
            Become a Constantine African Foundation volunteer missionary to help spread the Light and hope by teaching and 
            delivering your experiences practicing proclaiming the 
            workshop and sharing salvific messages to all of Africa. 
            </p>
            <p>
            be one of All missionary job opportunities that have been approved and received the blessing of local hierarchs or the 
            Metropolitan of the hosting country. 
            </p>
            <p>
            Discover the available prospects for fostering unity among individuals within the Constantine African Foundation.
            </p><br/>
            <h1 className=" text-3xl text-center" id="uganda">
            Uganda,
            </h1>
            <div>
            <div className="flex flex-col items-stretch px-5">
      <div className="text-black text-2xl w-full max-md:max-w-full">
      Empowering Uganda's Youth through Byzantine Art{" "}
      </div>
      <div className="w-full mt-10 max-md:max-w-full">
        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
          <div className="flex flex-col items-stretch w-[34%] max-md:w-full max-md:ml-0">
            <img
              loading="lazy"
              srcSet={asset3}
              className="aspect-[0.95] object-contain object-center w-full overflow-hidden max-md:mt-10"
            />
          </div>
          <div className="flex flex-col items-stretch w-[66%] ml-5 max-md:w-full max-md:ml-0">
            <div className={`text-black text-1xl ${showFullText1 ? '' : 'line-clamp-6'} mt-20 max-md:max-w-full max-md:mt-10`}>
            In order to revitalize and preserve the traditional art of Orthodox Byzantine art while creating opportunities for income generation, a multifaceted approach is crucial. This initiative seeks to empower the younger generation by enhancing 
            existing youth ministry programs and introducing specialized training in the realm of Byzantine art. Within the context of Uganda, where a substantial portion of the population consists of youth grappling with unemployment, 
            this endeavor takes on added significance. By offering guidance and education in Orthodox Byzantine art, it not only promotes cultural heritage but also equips individuals with the skills to generate income and secure a better future.
            This role is open to individuals from diverse backgrounds, including priests, monks, as well as those who are single or married. Their collective effort will not only breathe new life into the age-old art form but also serve as a beacon of hope for the youth, 
            enabling them to thrive and contribute to their communities, all while preserving the rich tradition of Orthodox Byzantine art.
              <br />
              <br />
              <h1>
              Missionary Job Description: 
              </h1>
              To revitalize and preserve the traditional art of Orthodox Byzantine art and create income opportunities, a multifaceted approach is essential. Our initiative empowers the youth in Uganda, combating unemployment. We enhance existing youth ministry programs and introduce specialized training in Byzantine art, promoting cultural heritage and equipping individuals for a brighter future.

This role welcomes diverse backgrounds - priests, monks, single, or married individuals. Together, we breathe new life into this ancient art, offering hope to the youth while preserving tradition.
              <h1>
              Demographic Notes: 
              </h1>
              In Uganda at main office, the Constantine Foundation finds inspiration in Byzantine art but realizes the need for standardization. They aim to pioneer the infusion of African elements, aiming to be a first in East Africa. This harmonious blend not only enriches cultural diversity but also seeks to establish a uniform and standardized expression of Byzantine art, uniting tradition with innovation, and paving the way for a unique artistic journey in the heart of Africa.
            <p>
            To Apply: Click below to submit a Missions Inquiry if you are interested in this opportunity. For questions, please contact our Missionary Director, at Volunteer@constantinefoundation.org 
Get ready for the upcoming workshop scheduled for the late 2024 to early 2025 period! Registration is now open.

            </p>
            </div>
            {!showFullText1 && (
              <button
                onClick={() => setShowFullText1(true)}
                className="w-[25%] bg-transparent hover:bg-darkgren text-darkgren font-semibold hover:text-white py-2 px-4 border border-darkgren hover:border-transparent rounded"
              >
                Read More
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
            </div>
            <div>
            <div className="flex flex-col items-stretch px-5">
      <div className="text-black text-2xl w-full max-md:max-w-full">
      Reviving Byzantine Marble Carving: Sustaining Tradition and Creating Income Opportunities{" "}
      </div>
      <div className="w-full mt-10 max-md:max-w-full">
        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
          <div className="flex flex-col items-stretch w-[34%] max-md:w-full max-md:ml-0">
            <img
              loading="lazy"
              src={asset2}
              className="aspect-[0.95] object-contain object-center w-full overflow-hidden max-md:mt-10"
            />
          </div>
          <div className="flex flex-col items-stretch w-[66%] ml-5 max-md:w-full max-md:ml-0">
            <div className={`text-black text-1xl ${showFullText2 ? '' : 'line-clamp-6'} mt-20 max-md:max-w-full max-md:mt-10`}>
            Reviving the ancient art of Byzantine marble carving is crucial for preserving tradition and generating income. To achieve this, fostering a thriving market is essential. By encouraging individuals to both learn and practice this craft, we can ensure its survival.

Empowering artisans and artists to create beautiful Byzantine marble pieces and connecting them with potential buyers will breathe new life into this art form. This not only helps artisans maintain a sense of cultural heritage but also enables them to earn a substantial income.

Moreover, in regions like Uganda, where unemployment among youth is a pressing issue, such initiatives can make a significant difference. By offering training and support, we can revitalize Byzantine marble carving and ensure its continued legacy while providing economic opportunities for those involved. Whether you're a monk, priest, single, or married, you can play a pivotal role in this revitalization effort.

              <br />
              <br />
              <h1>
              Missionary Job Description: Reviving Byzantine Marble Carving
              </h1>
              Reviving the ancient art of Byzantine marble carving is vital for tradition preservation and income generation. Fostering a thriving market is essential. Encouraging individuals to learn and practice this craft is key to its survival.

Empowering artisans and artists to create beautiful Byzantine marble pieces and connecting them with buyers rejuvenates this art form. This sustains cultural heritage and provides substantial income.

In regions like Uganda, where youth unemployment is pressing, such initiatives are impactful. By offering training and support, we revitalize Byzantine marble carving, ensuring its legacy and creating economic opportunities. Monks, priests, singles, or married individuals can all play a pivotal role in this revitalization effort.
              <p>
              To Apply: Click below to submit a Missions Inquiry if you are interested in this opportunity. For questions, please contact our Missionary Director, at Volunteer@constantinefoundation.org 
Get ready for the upcoming workshop scheduled for the late 2024 to early 2025 period! Registration is now open.
              </p>
            </div>
            {!showFullText2 && (
              <button
                onClick={() => setShowFullText2(true)}
                className="w-[25%] bg-transparent hover:bg-darkgren text-darkgren font-semibold hover:text-white py-2 px-4 border border-darkgren hover:border-transparent rounded"
              >
                Read More
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
            </div>
            <div>
            <div className="flex flex-col items-stretch px-5">
      <div className="text-black text-2xl w-full max-md:max-w-full">
      Reviving Byzantine Chanting for Generations{" "}
      </div>
      <div className="w-full mt-10 max-md:max-w-full">
        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
          <div className="flex flex-col items-stretch w-[34%] max-md:w-full max-md:ml-0">
            <img
              loading="lazy"
              src={asset1}
              className="aspect-[0.95] object-contain object-center w-full overflow-hidden max-md:mt-10"
            />
          </div>
          <div className="flex flex-col items-stretch w-[66%] ml-5 max-md:w-full max-md:ml-0">
            <div className={`text-black text-1xl ${showFullText3 ? '' : 'line-clamp-6'} mt-20 max-md:max-w-full max-md:mt-10`}>
            Rekindle the ancient art of Byzantine chanting and find profound meaning in your voice. Our seminars offer a unique opportunity for individuals from diverse backgrounds, whether you're a priest, a monk, single, or married. By immersing yourself in this sacred tradition, you not only add spiritual richness to your own life but also bring new vitality to our Church, all in the name of Jesus.

This collective effort isn't just about preserving tradition; it's about revitalizing it. Together, we breathe fresh life into this timeless art form, serving as a beacon of hope, especially for the younger generation. By learning and embracing the intricacies of Orthodox Byzantine chanting, we empower our youth to thrive and actively contribute to their communities, while safeguarding a heritage that has endured for centuries. 

Join us on this transformative journey, and be a part of something truly meaningful and enduring.

              <br />
              <br />
              <h1>
              Missionary Byzantine Chanting Instructor
              </h1>
              Are you a passionate advocate of Byzantine chanting and its spiritual significance? Join our mission as a Byzantine Chanting Instructor, where you'll share this ancient art form with diverse audiences, including priests, monks, singles, and married individuals. Your role goes beyond tradition preservation; it's about reinvigorating it. You'll collaborate with a community dedicated to breathing new life into this timeless practice, becoming a beacon of hope, especially for our youth.

As a Missionary Byzantine Chanting Instructor, you'll impart the intricacies of Orthodox Byzantine chanting, empowering the next generation to thrive and contribute to their communities. Your mission is to preserve a heritage that has endured for centuries, bringing profound meaning to both your life and the Church, all in the spirit of Jesus. Join us in this transformative journey, contributing to something enduring and truly meaningful.
              <h1>
              Demographic Notes: 
              </h1>
              <p>
              The Church in Uganda has been influenced by Byzantine style music and lacks standardization. There has also been a Protestant influence on their music in some communities. The Metropolitan would like to introduce African elements (the drum) and have standardized music for the Metropolis.
              </p>
              <p>
              To Apply: Click below to submit a Missions Inquiry if you are interested in this opportunity. For questions, please contact our Missionary Director, at Volunteer@constantinefoundation.org 
Get ready for the upcoming workshop scheduled for the late 2024 to early 2025 period! Registration is now open.

              </p>
            </div>
            {!showFullText3 && (
              <button
                onClick={() => setShowFullText3(true)}
                className="w-[25%] bg-transparent hover:bg-darkgren text-darkgren font-semibold hover:text-white py-2 px-4 border border-darkgren hover:border-transparent rounded"
              >
                Read More
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
            </div>
        </div>
        <div id="third section">

        </div>
        <div id="fourth section">

        </div>
      </div>
    </>
  );
};

export default Careers;
