import React from "react";
import BlogsHeader from "../../../../src/assets/Constantine Bloges Header .png";

const Header = () => {
  return (
    <div>
      <div
        className="relative lg:h-[54rem] md:h-[35rem] h-[18rem] w-full overflow-hidden flex items-end"
        id="header"
      >
        <img
          src={BlogsHeader}
          alt="headerimage"
          className="absolute inset-0 -z-10  w-full object-cover object-right md:object-center"
        />
      </div>
    </div>
  );
};

export default Header;
