import { createSlice } from "@reduxjs/toolkit";

const allCampaignsInitialState = {
  allCampaigns: {
    isLoading: false,
    data: [],
    errors: "",
  },
};

export const getAllCampaignsSlice = createSlice({
  name: "all_Campaigns",
  initialState: allCampaignsInitialState,
  reducers: {
    getAllCampaignsAction: (state) => {
      state.allCampaigns.isLoading = true;
      state.allCampaigns.errors = "";
    },
    getAllCampaignsSuccessAction: (state, action) => {
      state.allCampaigns.isLoading = false;
      state.allCampaigns.data = action.payload;
    },
    getAllCampaignsFailureAction: (state, action) => {
      state.allCampaigns.isLoading = false;
      state.allCampaigns.errors = action.payload;
    },
  },
});

export const {
  getAllCampaignsAction,
  getAllCampaignsSuccessAction,
  getAllCampaignsFailureAction,
} = getAllCampaignsSlice.actions;

export default getAllCampaignsSlice.reducer;
