import React from "react";
import { useUser } from "./UserContext";

const Payment = () => {
    // const { user } = useUser();

    return (
        <React.Fragment>
            <div className="bg-primary text-white p-10 m-10">
                <p className="text-5xl p-10 m-10">Hello the payment method coming soon!</p>
            </div>
        </React.Fragment>
    )
}

export default Payment;