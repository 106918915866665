import { put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  getAllBlogsAction,
  getAllBlogsFailureAction,
  getAllBlogsSuccessAction,
} from "../slices/getBlogsSlice";

function* getAllBlogs() {
  try {
    yield put(getAllBlogsAction());
    const response = yield axios.get("/blogs");
    yield put(getAllBlogsSuccessAction(response.data));
  } catch (error) {
    yield put(getAllBlogsFailureAction(error));
  }
}

export function* watchGetAllBlogs() {
  yield takeLatest("GET_ALL_BLOGS", getAllBlogs);
}
