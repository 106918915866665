import { url } from "../../data";
import EventCard from "./EventCard";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";

function EventSub() {
  const location = useLocation();
  const state = location.state;
  return (
    <>
      <div className={`h-[60vh] bg-lightgray relative`}>
        <div className=" absolute h-full w-full overflow-hidden">
          <img
            src={`${url}${state.image}`}
            className=" h-full w-full object-cover blur-[4px]"
            alt="eventimage"
          />
        </div>
        <motion.h1
          initial={{
            x: 100,
          }}
          whileInView={{
            x: 0,
            transition: {
              duration: 0.8,
              bounce: 5,
              type: "easy",
              delay: 0.1,
            },
          }}
          className="weird-font absolute bottom-10 text-[3rem] text-orange left-10"
        >
          {state.heading}
        </motion.h1>
      </div>
      <div className="flex justify-center items-center p-20">
        <div className="flex w-full ">
          <div className="w-3/5 pr-5 border-r-2 border-green ">
            <motion.h2
              initial={{
                x: 100,
              }}
              whileInView={{
                x: 0,
                transition: {
                  duration: 0.8,
                  bounce: 5,
                  type: "easy",
                  delay: 0.1,
                },
              }}
              className=" font-roboto text-[1.5rem] text-greenshade font-bold mb-5"
            >
              Description
            </motion.h2>
            <motion.p
              initial={{
                y: 0,
                scale: 0,
                opacity: 0,
              }}
              whileInView={{
                y: 0,
                scale: 1,
                opacity: 1,
                transition: {
                  duration: 0.8,
                  bounce: 1,
                  type: "easy",
                  delay: 0.3,
                },
              }}
              className=" font-roboto text-[1rem] text-greenshade mb-5"
            >
              {state.text}
            </motion.p>
            <div className="flex justify-content-between">
              <motion.div
                initial={{
                  x: 100,
                }}
                whileInView={{
                  x: 0,
                  transition: {
                    duration: 0.8,
                    bounce: 5,
                    type: "easy",
                    delay: 0.1,
                  },
                }}
                className=" py-1 px-4 rounded-full bg-buttongreen text-white"
              >
                {state.eventType}
              </motion.div>
            </div>
          </div>
          <div className=" w-2/5 flex items-start">
            <EventCard eventData={state} />
          </div>
        </div>
      </div>
    </>
  );
}

export default EventSub;
