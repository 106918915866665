import React from "react";
import { motion } from "framer-motion";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { datToString } from "../../utils/dateToString";

const EventCard = (props) => {
  const { eventData } = props;
  return (
    <motion.div
      initial={{
        y: 0,
        scale: 0,
        opacity: 0,
      }}
      whileInView={{
        y: 0,
        scale: 1,
        opacity: 1,
        transition: {
          duration: 0.5,
          bounce: 1,
          type: "easy",
          delay: 0.1,
        },
      }}
      className=" p-5 w-full flex-none relative"
    >
      <div className=" relative p-5 glass ">
        <div className="flex justify-between items-center mb-5">
          <motion.h1
            initial={{
              x: -100,
            }}
            whileInView={{
              x: 0,
              transition: {
                duration: 0.8,
                bounce: 5,
                type: "easy",
                delay: 0.1,
              },
            }}
            className={" text-[2.5rem] text-greenshade"}
          >
            {datToString(eventData.startdate).split("/")[0]}
          </motion.h1>
          <p style={{ color: "#83c970" }}>
            {eventData.eventPrice.toString() === "0"
              ? "Free Pass"
              : eventData.eventPrice}
          </p>
        </div>
        <h2 className=" text-[2rem] font-roboto mb-3">
          {datToString(eventData.startdate).split("/").slice(0, -1).join("/")} -{" "}
          {datToString(eventData.enddate).split("/").slice(0, -1).join("/")}
        </h2>
        <motion.p
          initial={{
            x: -100,
          }}
          whileInView={{
            x: 0,
            transition: {
              duration: 0.8,
              bounce: 5,
              type: "easy",
              delay: 0.1,
            },
          }}
          className={"text-[1.2rem] text-green mb-3"}
        >
          <LocationOnOutlinedIcon />
          {eventData.address}
        </motion.p>
        <motion.div
          initial={{
            y: 0,
            scale: 0,
            opacity: 0,
          }}
          whileInView={{
            y: 0,
            scale: 1,
            opacity: 1,
            transition: {
              duration: 0.8,
              bounce: 1,
              type: "easy",
              delay: 0.3,
            },
          }}
          className="flex justify-end "
        >
          <div className=" text-greenshade bg-white shadow-xl border-2 rounded-3xl px-4 py-2 text-[1.2rem] cursor-pointer">
            Book now
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default EventCard;
