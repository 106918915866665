import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import axios from "axios";
import { SampleNextArrow, SamplePrevArrow } from "../../components/SliderArow";
import boxp1 from "../../assets/Headers/SVG/Asset_2.svg";
import boxp2 from "../../assets/Headers/SVG/Asset_3.svg";
import { useNavigate } from "react-router-dom";

const UpcomingEvent = () => {
  const navigate = useNavigate();
  const [Data, setData] = useState(null);
  const [SlideToShow, setSlideToShow] = useState(
    window.matchMedia("(max-width: 639px)").matches ? 1 : 3
  );

  const url = process.env.REACT_APP_API_URL + `/events?populate=*`;

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios({
        method: "get",
        url: url,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      setData(data?.data);
    };

    fetchData();
  }, [url]);

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: SlideToShow,
    slidesToScroll: 1,
    className: "campaings w-inherit",
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    // rows: 1,
  };

  return (
    <div className="flex mt-0 z-10 flex-col relative text-center bg-gradient-to-r from-green via-greenlight to-darkgren">
      <p className="md:text-[3.5rem] text-[2rem] text-white">Upcoming Events</p>
      <p className="md:text-[3.5rem] text-[2rem] text-white">The Events to Engaging And Have Fun</p>
      <div
        className="font-roboto w-full mt-5 md:mt-20"
        style={{
          textAlign: "-webkit-center",
        }}
      >
        <Slider {...settings}>
              {Data?.map((event) => (
                <div className="h-[28rem]" key={event.id}>
                  <motion.div
                    viewport={{ once: true }}
                    className="m-auto relative bg-white rounded-[3rem] md:h-[17rem] h-[9rem] md:mx-10 mx-1 md:hover:mx-7 hover:mx-2 md:w-[15rem] w-[11rem] md:hover:h-[20rem] hover:h-[11.5rem] md:hover:w-[18rem] hover:w-[12rem] group"
                  >
                    <img
                      alt="eventImage"
                      src={process.env.REACT_APP_API_IMAGE_URL + event.attributes?.imageURL?.data[0].attributes?.url}
                      // src={process.env.REACT_APP_API_IMAGE_URL + x?.attributes?.imageURL?.data[0].attributes?.url}
                      className="w-full h-full object-cover rounded-[3rem]"
                    />
                    <div className="absolute top-[90%] left-[50%] -translate-x-[50%] w-11/12 m-auto p-3 bg-white rounded-[1rem] drop-shadow-[0_7px_7px_rgba(0,0,0,0.3)] text-left -translate-y-10">
                      <p className="pl-2 mb-2 text-green font-bold">
                        {event.attributes.name}
                      </p>

                      <div className={`group-hover:block block sm:hidden overflow-hidden`}>
                        <div className="w-full flex justify-start text-white font-bold">
                                <button
                                  href="#"
                                  className=" mb-3 rounded-xl inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white hover:text-darkgren bg-darkgren hover:bg-white hover:ring-darkgren"
                                  onClick={() => {
                                    navigate("/upeventdetail/" + event.id);
                                    window.scrollTo(0, 0);
                                  }}
                                  >
                                  Join The Event
                                </button>
                        </div>
                      </div>
                      {/* <div className="w-full h-11 items-center bg-orange rounded-xl">
                      
                      </div> */}
                      <p className=" p-2 items-center bg-green text-white rounded-xl">
                      {event.attributes.start_date}
                      </p>
                    </div>
                  </motion.div>
                </div>
              ))
          }
        </Slider>
      </div>
      <div className="gap- mt-20 font-sans"></div>
    </div>
  );
};

export default UpcomingEvent;
