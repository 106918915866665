export const GlobalDebug = (function () {
    var savedConsole = console;
    /**
    * @param {boolean} debugOn
    * @param {boolean} suppressAll
    */
    return function (debugOn, suppressAll) {
      var suppress = suppressAll || false;
      if (debugOn === false) {
        // supress the default console functionality
        console = {};
        console.log = function () {};
        // supress all type of consoles
        if (suppress) {


          console.info = function () {};
          console.warn = function () {};
          console.error = function () {};
          console.log = function () {};
        } else {


          console.info = "";
          console.warn =  "";
          console.error = "";
        }
      } else {

        console.info = function () {};
        console.warn = function () {};
        console.error = function () {};
        console.log = function () { };
        console.debug = function () { };
      }
    };
  })();