// import React, { useState } from "react";
// import FormImageBackgrouond from "../../assets/Contat Us Page/SVG/Asset 4.svg";
// import FormImage from "../../assets/Contat Us Page/SVG/Asset 3.svg";
// import { motion } from "framer-motion";
// import axios from 'axios';

// const Form = () => {
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     feedback: ''

//   });

//   const [formStatus, setFormStatus] = useState(null);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const payload = {
//       data: {
//         name: formData.name,
//         email: formData.email,
//         phone: formData.phone,
//         feedback: formData.feedback
//       }
//     };

//     try {
//       const response = await axios.post(`${process.env.REACT_APP_API_URL}/contacts`, payload, {
//         headers: {
//           'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`,
//           'Content-Type': 'application/json'
//         }
//       });

//       if (response.status === 200 || response.status === 201) {
//         console.log('Feedback sent successfully:', response.data);
//         setFormStatus('success');
//       } else {
//         console.error('Failed to submit form:', response.statusText);
//         setFormStatus('error');
//       }
//     } catch (error) {
//       if (error.response) {
//         console.error('Error response from server:', error.response.data);
//       } else if (error.request) {
//         console.error('No response received:', error.request);
//       } else {
//         console.error('Error in setting up the request:', error.message);
//       }
//       setFormStatus('error');
//     }
//   };

//   return (
//     <div className="w-[85%] m-auto flex flex-col gap-1 items-center text-center h-full relative">
//       <p className="text-[3rem] md:text-[3.5rem] text-greenshade">
//         Contact Form
//       </p>
//       <motion.p
//         initial={{
//           y: 10,
//           scale: 0.1,
//         }}
//         whileInView={{
//           y: 0,
//           scale: 1,
//           transition: {
//             duration: 0.8,
//             bounce: 0.1,
//             type: "easy",
//           },
//         }}
//         viewport={{ once: true }}
//         className=" md:text-[1.5rem] text-[1rem] text-green mb-3 md:mb-5 capitalize "
//       >
//         For more detail Contact Us by filling out the form
//       </motion.p>
//       <div className="flex flex-wrap justify-between w-4/5 lg:pt-28 mt-10 ">
//       <motion.div
//           initial={{
//             y: 250,
//             opacity: 0,
//           }}
//           whileInView={{
//             opacity: 1,
//             y: 0,
//             scale: 1,
//             transition: {
//               duration: 0.8,
//               delay: 0,
//             },
//           }}
//           viewport={{ once: true }}
//           className="w-full lg:w-1/2  h-[22rem] sm:h-[40rem] md:h-[27rem] lg:h-3/4 py-20 relative flex"
//         >
//           <img
//             src={FormImageBackgrouond}
//             className="w-11/12 lg:w-8/12 h-[14rem] md:h-[20rem] lg:h-[21rem]  absolute md:left-[12%] md:top-[15%] lg:left-[38%] z-10 lg:top-[-12%] left-[12%]"
//             alt="BackgroundImage"
//           />
//           <img
//             src={FormImage}
//             className="z-20 absolute h-[14rem] md:h-[20rem] lg:h-[21rem]  w-4/5 md:w-2/3 lg:w-1/2 md:left-[3%] md:top-[25%] lg:left-[33%] lg:top-[20%] left-[2%]"
//             alt="Man sitting in a chair in front of computer"
//           />
//         </motion.div>
//         <motion.div
//           initial={{
//             y: 250,
//             opacity: 0,
//           }}
//           whileInView={{
//             opacity: 1,
//             y: 0,
//             scale: 1,
//             transition: {
//               duration: 0.8,
//               delay: 0,
//             },
//           }}
//           viewport={{ once: true }}
//           className="w-full md:w-1/2 lg:w-2/5"
//         >
//           {formStatus === 'success' && (
//               <div className="mb-4 text-green">
//                 Feedback sent successfully!
//               </div>
//             )}
//             {formStatus === 'error' && (
//               <div className="mb-4 text-red-600">
//                 There was an error sending the feedback. Please try again.
//               </div>
//             )}
//           <form className=" flex flex-col flex-wrap" onSubmit={handleSubmit}>
//             <div className="flex flex-wrap justify-between pb-5">
//               <div className="relative h-11 w-full md:w-2/5 min-w-[150px]">
//                 <input
//                   className="peer h-full w-full border-b-4 border-blue-gray-200 bg-transparent pt-4 pb-1.5  text-sm font-bold font-sans text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-pink-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-5"
//                   placeholder=""
//                   required
//                   name="name"
//                   value={formData.name}
//                   onChange={handleChange}
//                 />
//                 <label className="after:content[' '] text-greenshade pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-sans font-bold leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
//                   Full Name
//                 </label>
//               </div>
//               <div className="relative w-full h-11 md:w-2/5 min-w-[150px]">
//                 <input
//                   className="peer h-full w-full border-b-4 border-blue-gray-200 bg-transparent pt-4 pb-1.5 text-sm font-bold font-sans text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-pink-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
//                   placeholder=""
//                   required
//                   type="email"
//                   name="email"
//                   value={formData.email}
//                   onChange={handleChange}
//                 />
//                 <label className="after:content[' '] text-greenshade pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-bold font-sans  leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
//                   Email
//                 </label>
//               </div>
//             </div>
//             <div className="relative h-11 w-full min-w-[200px]">
//               <input
//                 className="peer h-full w-full border-b-4 font-bold border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm  text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-pink-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
//                 placeholder=""
//                 required
//                 name="phone"
//                 value={formData.phone}
//                 onChange={handleChange}
//               />
//               <label className="after:content[' ']  text-greenshade pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-sans font-bold leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
//                 Phone
//               </label>
//             </div>
//             <div className="py-8 flex flex-col">
//               <label className="text-greenshade py-2 text-start font-sans font-bold self-start">
//                 Feedback or Questions
//               </label>
//               <textarea
//                 className="w-full h-32 px-4 py-3 border-4 rounded-lg font-sans"
//                 value={formData.feedback}
//                 name="feedback"
//                 onChange={handleChange}
//               ></textarea>
//             </div>
//             <button
//               type="submit"
//               className="font-sans font-semibold text-white bg-buttongreen w-24 py-1 px-2 rounded-lg self-end "
//             >
//               Send
//             </button>
//           </form>
//         </motion.div>
//       </div>

import React, { useState } from "react";
import FormImageBackgrouond from "../../assets/Contat Us Page/SVG/Asset 4.svg";
import FormImage from "../../assets/Contat Us Page/SVG/Asset 3.svg";
import { motion } from "framer-motion";
import axios from 'axios';

const Form = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    feedback: ''
  });

  const [formStatus, setFormStatus] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      data: {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        feedback: formData.feedback
      }
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/contacts`, payload, {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200 || response.status === 201) {
        console.log('Feedback sent successfully:', response.data);
        setFormStatus('success');
        // Clear form data
        setFormData({ name: '', email: '', phone: '', feedback: '' });
      } else {
        console.error('Failed to submit form:', response.statusText);
        setFormStatus('error');
      }
    } catch (error) {
      if (error.response) {
        console.error('Error response from server:', error.response.data);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error in setting up the request:', error.message);
      }
      setFormStatus('error');
    }
  };

  return (
    <div className="w-[85%] m-auto flex flex-col gap-1 items-center text-center h-full relative">
      <p className="text-[3rem] md:text-[3.5rem] text-greenshade">
        Contact Form
      </p>
      <motion.p
        initial={{
          y: 10,
          scale: 0.1,
        }}
        whileInView={{
          y: 0,
          scale: 1,
          transition: {
            duration: 0.8,
            bounce: 0.1,
            type: "easy",
          },
        }}
        viewport={{ once: true }}
        className=" md:text-[1.5rem] text-[1rem] text-green mb-3 md:mb-5 capitalize "
      >
        For more detail Contact Us by filling out the form
      </motion.p>
      <div className="flex flex-wrap justify-between w-4/5 lg:pt-28 mt-10 ">
        <motion.div
          initial={{
            y: 250,
            opacity: 0,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            scale: 1,
            transition: {
              duration: 0.8,
              delay: 0,
            },
          }}
          viewport={{ once: true }}
          className="w-full lg:w-1/2  h-[22rem] sm:h-[40rem] md:h-[27rem] lg:h-3/4 py-20 relative flex"
        >
          <img
            src={FormImageBackgrouond}
            className="w-11/12 lg:w-8/12 h-[14rem] md:h-[20rem] lg:h-[21rem]  absolute md:left-[12%] md:top-[15%] lg:left-[38%] z-10 lg:top-[-12%] left-[12%]"
            alt="BackgroundImage"
          />
          <img
            src={FormImage}
            className="z-20 absolute h-[14rem] md:h-[20rem] lg:h-[21rem]  w-4/5 md:w-2/3 lg:w-1/2 md:left-[3%] md:top-[25%] lg:left-[33%] lg:top-[20%] left-[2%]"
            alt="Man sitting in a chair in front of computer"
          />
        </motion.div>
        <motion.div
          initial={{
            y: 250,
            opacity: 0,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            scale: 1,
            transition: {
              duration: 0.8,
              delay: 0,
            },
          }}
          viewport={{ once: true }}
          className="w-full md:w-1/2 lg:w-2/5"
        >
          {formStatus === 'success' && (
            <div className="mb-4 text-green">
              Feedback sent successfully!
            </div>
          )}
          {formStatus === 'error' && (
            <div className="mb-4 text-red-600">
              There was an error sending the feedback. Please try again.
            </div>
          )}
          <form className=" flex flex-col flex-wrap" onSubmit={handleSubmit}>
            <div className="flex flex-wrap justify-between pb-5">
              <div className="relative h-11 w-full md:w-2/5 min-w-[150px]">
                <input
                  className="peer h-full w-full border-b-4 border-blue-gray-200 bg-transparent pt-4 pb-1.5  text-sm font-bold font-sans text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-pink-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-5"
                  placeholder=""
                  required
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                <label className="after:content[' '] text-greenshade pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-sans font-bold leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Full Name
                </label>
              </div>
              <div className="relative w-full h-11 md:w-2/5 min-w-[150px]">
                <input
                  className="peer h-full w-full border-b-4 border-blue-gray-200 bg-transparent pt-4 pb-1.5 text-sm font-bold font-sans text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-pink-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                  placeholder=""
                  required
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <label className="after:content[' '] text-greenshade pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-bold font-sans  leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Email
                </label>
              </div>
            </div>
            <div className="relative h-11 w-full min-w-[200px]">
              <input
                className="peer h-full w-full border-b-4 font-bold border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm  text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-pink-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                placeholder=""
                required
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
              <label className="after:content[' ']  text-greenshade pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-sans font-bold leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Phone Number
              </label>
            </div>
            <div className="">
              <textarea
                name="feedback"
                value={formData.feedback}
                onChange={handleChange}
                // placeholder="Message"
                rows={4}
                required
                className="peer h-full w-full border-b-4 font-bold border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-pink-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              />
              <label className="after:content[' '] text-greenshade pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-bold font-sans leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Feedback
              </label>
            </div>
            <button
              type="submit"
              className="bg-greenshade mt-4 h-[50px] rounded-lg text-white text-lg font-bold shadow-lg"
            >
              Send
            </button>
          </form>
        </motion.div>
      </div>

      
      <div className="flex flex-wrap xl:pt-24 justify-around">
      <div className="flex flex-col items-start gap-4 w-4/5 md:w-2/4 lg:w-2/6 text-justify self-center">
  <p className="font-sans font-bold text-2xl text-greenshade">
    Come Visit Us
  </p>
  <p className="font-sans font-semibold">
    Please don't be shy to contact us or drop by our office to know more
    about what we are doing. We will be happy to have you.
  </p>
  <p className="font-sans font-semibold">
    <strong className="text-greenshade py-5">Constantine African Foundation</strong><br />
    V271 Flower Str.<br />
    Abeka Lapaz, Accra<br />
    Ghana
  </p>
</div>

        <div className="md:pb-28 pb-16 pt-24">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1015686.7844336793!2d-1.0512994177051136!3d6.059492257449375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf994cb8f8dd75%3A0x2c3eb6f62c302343!2sHoly%20Transfiguration%20Orthodox%20Cathedral!5e0!3m2!1sen!2set!4v1726857314337!5m2!1sen!2set"
            className="border-8 w-full h-[15rem] mt-[3rem] md:mt-0 lg:h-[400px] lg:w-[400px] border-greenshade"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="location"
          ></iframe>
          {/* <iframe src="" width="800" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
        </div>
        <div className="w-4/5 m-auto h-1  bg-black"></div>
      </div>
    </div>
  );
};

export default Form;
