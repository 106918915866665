import React, { useEffect, useReducer, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { getError } from "../utils";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import Product from "../components/Product";
import "react-toastify/dist/ReactToastify.css";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        products: action.payload.products,
        countProducts: action.payload.countProducts,
        loading: false,
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default function SearchScreen() {
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const category = sp.get("category") || "all";
  console.log(category,'category')
const [newproduct, setnewproduct] = useState([])
  const [{ loading, error, products, countProducts }, dispatch] = useReducer(
    reducer,
    {
      products: [],
      loading: true,
      error: "",
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          process.env.REACT_APP_API_URL + `/products?populate=*&filters[catagory][name][$eq]=${category}`,
          { headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
          }}
        );
        setnewproduct(data)
        dispatch({ type: "FETCH_SUCCESS", payload: data });
      } catch (error) {
        dispatch({
          type: "FETCH_FAIL",
          payload: getError(error),
        });
      }
    };
    fetchData();
  }, [category, error]);
console.log(newproduct,'newproduct')
  return (
    <div className="h-full p-[2rem] bg-[#0F6E42] rounded-tl-[2rem] ">
      <div className="flex flex-col">
        <div className="md:flex justify-between mb-3">
          <div className="md:w-9/12">
            <div className="text-white text-base">
              {loading ? (
                <LoadingBox></LoadingBox>
              ) : error ? (
                <MessageBox variant="danger">{error}</MessageBox>
              ) : (
                <>
                  <div className="text-xl">
                    {countProducts === 0 ? "No" : countProducts} Results
                    {category !== "all" && `: ${category}`}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {newproduct?.data?.length === 0 && <MessageBox>No Product Found</MessageBox>}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-3">
          {newproduct?.data?.map((product) => (
            <div key={product.id} className="mb-3">
              {products?.data?.id}
              <Product product={product}  ></Product>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
