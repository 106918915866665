import { createSlice } from "@reduxjs/toolkit";

const campaignInitialState = {
  campaign: {
    isLoading: false,
    data: null,
    errors: "",
  },
};

export const campaignSlice = createSlice({
  name: "campaigns",
  initialState: campaignInitialState,
  reducers: {
    createCampaignAction: (state, action) => {
      state.campaign.data = action.payload;
      state.campaign.errors = "";
      state.campaign.isLoading = true;
    },

    createCampaignSuccessAction: (state, action) => {
      state.campaign.isLoading = false;
      state.campaign.data = action.payload;
    },
    createCampaignFailureAction: (state, action) => {
      state.campaign.isLoading = false;
      state.campaign.errors = action.payload;
    },
    updateCampaignAction: (state, action) => {
      state.campaign.data = action.payload;
      state.campaign.isLoading = true;
    },
    updateCampaignSuccessAction: (state, action) => {
      state.campaign.data = action.payload;
      state.campaign.isLoading = false;
    },
    updateCampaignFailureAction: (state, action) => {
      state.campaign.errors = action.payload;
      state.campaign.isLoading = false;
    },
    deleteCampaignAction: (state, action) => {
      state.campaign.data = action.payload;
      state.campaign.isLoading = false;
    },
    deleteCampaignSuccessAction: (state, action) => {
      state.campaign.data = action.payload;
      state.campaign.isLoading = false;
    },
    deleteCampaignFailureAction: (state, action) => {
      state.campaign.errors = action.payload;
      state.campaign.isLoading = false;
    },
    getCampaignAction: (state) => {
      state.campaign.isLoading = true;
      state.campaign.errors = "";
    },
    getCampaignSuccessAction: (state, action) => {
      state.campaign.isLoading = false;
      state.campaign.data = action.payload;
    },
    getCampaignErrorAction: (state, action) => {
      state.campaign.isLoading = false;
      state.campaign.errors = action.payload;
    },
  },
});

export const {
  getCampaignAction,
  getCampaignSuccessAction,
  getCampaignErrorAction,
  createCampaignAction,
  createCampaignSuccessAction,
  createCampaignFailureAction,
  updateCampaignAction,
  updateCampaignSuccessAction,
  updateCampaignFailureAction,
  deleteCampaignAction,
  deleteCampaignSuccessAction,
  deleteCampaignFailureAction,
} = campaignSlice.actions;

export default campaignSlice.reducer;
