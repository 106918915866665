import createSagaMiddlware from "@redux-saga/core";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./redux/reducers";
import rootSaga from "./saga/rootSaga";

const sagaMiddleWare = createSagaMiddlware();

const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleWare],
});
sagaMiddleWare.run(rootSaga);

export default store;
