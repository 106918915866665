import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const NumberCount = ({ targetNumber, c }) => {
  const [count, setCount] = useState(c);

  useEffect(() => {
    if (count < targetNumber) {
      const interval = setInterval(() => {
        setCount(count + 1);
      }, 10);

      return () => {
        clearInterval(interval);
      };
    } else if (count > targetNumber) {
      setCount(0);
    }
  }, [count, targetNumber]);

  return <div>{count}%</div>;
};

const ProgressBar = ({ progress }) => {
  return (
    <motion.div className="w-full h-6 bg-orange rounded-full mb-2rem">
      <motion.div
        className="h-full bg-green rounded-full"
        animate={{
          width: [`${0}%`, `${progress}%`],
        }}
        transition={{
          duration: 6,
        }}
      ></motion.div>
      <NumberCount targetNumber={progress} c={0} />
    </motion.div>
  );
};

export default ProgressBar;
