import React, { useEffect } from "react";
import Header from "./Header";
import Body from "./Body";
import BlogMore from "./BlogMore";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { url } from "../../../data";
import { useState } from "react";
import axios from "axios";

const BlogDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const blog = useSelector((state) => state.Blog.blog);

  console.log ('hello')


  const [Data, setData]= useState(null)
 
const url = process.env.REACT_APP_API_URL + `/blogs/${id}?populate=*`;
const headers = {
  "Authorization":"bearer" + process.env.REACT_APP_API_TOKEN 
}
console.log(url,'url')
 
useEffect(() => {
  const fetchData =  async () => {
  
    const {data} =   await axios({
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
      },
    
    }) 
    console.log(data?.data,'blog error')

    setData(data?.data  )
   
  console.log(Data,' Data') 
}

fetchData()

},[url])

 
    return (
      <> 
        {/* <Header  Data={Data}/> */}
        <Body  Data={Data} />
        {/* <BlogMore Data={Data} /> */}
      </>
    );
  
};

export default BlogDetail;
