import React from "react";
import NavBar from "../../../components/NavBar";
import Header from "./Header";
import Body from "./Body";

const BlogList = () => {
  return (
    <>
      {/* <NavBar /> */}
      <Header />
      <Body />
    </>
  );
};

export default BlogList;
