import { useEffect, useState } from "react";
import axios from "axios";
import Campain from "./singCampain";
import Donors from "../donors";
// import Event from "../../shopandeventsrc/components/Event";
import Subscription from "../../components/Subscription";

const CampainList = () => {
  const [Data, setData] = useState(null);

  const url = process.env.REACT_APP_API_URL + `/campaigns?populate=*`;
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios({
          method: "get",
          url: url,
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          },
        });

        setData(data?.data);
      } catch (error) {
        console.log("Error fetching campaign data", error);
      }
    };

    fetchData();
  }, [url]);

  return (
    <div className="bg-darkgren">
      <h1 className="text-3xl md:text-5xl text-white text-center p-4 md:pl-10 md:pt-10 md:pr-10 pb-1">
        Current Active Campaigns
      </h1>

      <div className="flex flex-col md:flex-row gap-10 pt-5 lg:ml-10 lg:mr-10 justify-center">
        <div className="w-full md:w-4/6 lg:w-6/6 md:border-r-2 border-green pr-2">
          <div className="overflow-y-auto md:h-[40rem] scrollbar-curved px-5">
            {Data === null ? (
              <div className="flex flex-col items-center justify-center h-[25rem]">
                <div className="w-24 h-24 border-l-2 border-gray-900 rounded-full animate-spin"></div>
                <div className="pt-[2rem] text-white">Loading...</div>
              </div>
            ) : (
              <Campain Data={Data} />
            )}
          </div>
        </div>

        <div className="w-full md:w-2/6">
          {/* You can uncomment the Event component when necessary */}
          {/* <Event /> */}
          <Donors />
        </div>
      </div>
    </div>
  );
};

export default CampainList;
