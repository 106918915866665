import { createSlice } from "@reduxjs/toolkit";

const blogInitialState = {
  blog: {
    isLoading: false,
    data: null,
    errors: "",
  },
};

export const blogSlice = createSlice({
  name: "blogs",
  initialState: blogInitialState,
  reducers: {
    createBlogAction: (state, action) => {
      state.blog.data = action.payload;
      state.blog.errors = "";
      state.blog.isLoading = true;
    },

    createBlogSuccessAction: (state, action) => {
      state.blog.isLoading = false;
      state.blog.data = action.payload;
    },
    createBlogFailureAction: (state, action) => {
      state.blog.isLoading = false;
      state.blog.errors = action.payload;
    },
    updateBlogAction: (state, action) => {
      state.blog.data = action.payload;
      state.blog.isLoading = true;
    },
    updateBlogSuccessAction: (state, action) => {
      state.blog.data = action.payload;
      state.blog.isLoading = false;
    },
    updateBlogFailureAction: (state, action) => {
      state.blog.errors = action.payload;
      state.blog.isLoading = false;
    },
    deleteBlogAction: (state, action) => {
      state.blog.data = action.payload;
      state.blog.isLoading = false;
    },
    deleteBlogSuccessAction: (state, action) => {
      state.blog.data = action.payload;
      state.blog.isLoading = false;
    },
    deleteBlogFailureAction: (state, action) => {
      state.blog.errors = action.payload;
      state.blog.isLoading = false;
    },
    getBlogAction: (state) => {
      state.blog.isLoading = true;
      state.blog.errors = "";
    },
    getBlogSuccessAction: (state, action) => {
      state.blog.isLoading = false;
      state.blog.data = action.payload;
    },
    getBlogErrorAction: (state, action) => {
      state.blog.isLoading = false;
      state.blog.errors = action.payload;
    },
  },
});

export const {
  getBlogAction,
  getBlogSuccessAction,
  getBlogErrorAction,
  createBlogAction,
  createBlogSuccessAction,
  createBlogFailureAction,
  updateBlogAction,
  updateBlogSuccessAction,
  updateBlogFailureAction,
  deleteBlogAction,
  deleteBlogSuccessAction,
  deleteBlogFailureAction,
} = blogSlice.actions;

export default blogSlice.reducer;
