import axios from "axios";
import { put, takeLatest } from "redux-saga/effects";
import {
  updateCampaignAction,
  updateCampaignFailureAction,
  updateCampaignSuccessAction,
} from "../slices/campaignSlice";

function* updateCampaignSaga({ id, payload }) {
  try {
    yield put(updateCampaignAction());
    const response = yield axios.patch(`/campaigns/${id}`, payload);
    yield put(updateCampaignSuccessAction(response.data));
  } catch (error) {
    yield put(updateCampaignFailureAction(error));
  }
}

export function* watchUpdateCampaignSaga() {
  yield takeLatest("UPDATE_CAMPAIGN_BY_ID", updateCampaignSaga);
}
