

// import React from 'react';

// const Subscription = () => {
//   return (
//     <div className="text-center w-[90%] max-w-[100%] text-greenshade bg-white bg-opacity-80 p-4 rounded-lg mx-auto">
//       {/* Responsive Heading */}
//       <div className="text-[1rem] md:text-[1.2rem] lg:text-[1.5rem] mb-2">
//         Stay in the loop with the latest updates!
//       </div>

//       {/* Responsive Paragraph */}
//       <p className="font-roboto text-[0.7rem] md:text-[0.8rem] lg:text-[0.9rem] text-gray-600 mb-4">
//         Subscribe now to receive notifications about new events, exciting campaigns, and captivating
//         blog posts directly to your inbox. Don't miss out on any important information or engaging
//         content.
//       </p>

//       {/* Responsive Input and Button */}
//       <div className="font-roboto flex flex-col md:flex-col gap-2 justify-center items-center mb-2">
//         <input
//           type="email"
//           placeholder="Enter email address"
//           className="pl-2 w-full sm:w-[16rem] outline-none focus:outline-none border-2 border-gray-300 rounded-lg py-2"
//         />
//         <button className="px-4 py-2 w-full sm:w-auto text-white bg-green rounded-lg hover:bg-green-600 transition-all duration-300">
//           Subscribe
//         </button>
//       </div>

//       {/* Responsive Footer Text */}
//       <p className="font-roboto text-[0.6rem] md:text-[0.7rem] lg:text-[0.8rem] text-gray-600">
//         Join our subscription list today and be the first to know!
//       </p>
//     </div>
//   );
// };

// export default Subscription;

import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const Subscription = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    if (!email) {
      setMessage('Please enter a valid email address.');
      return;
    }

    // EmailJS configuration
    const serviceID = 'service_iadmled';
    const templateID = 'template_nitjzs7';
    const userID = 'GyNZBTO-r8jLifeFq';

    const templateParams = {
      to_email: 'subscribers@constantinefoundation.org',
      from_email: email,
    };

    emailjs.send(serviceID, templateID, templateParams, userID)
      .then((response) => {
        console.log('Email sent successfully:', response.status, response.text);
        setMessage('Subscription successful! Thank you for subscribing.');
        setEmail('');
      })
      .catch((error) => {
        console.error('Failed to send email:', error);
        setMessage('Subscription failed. Please try again later.');
      });
  };

  return (
    <div className="text-center w-[90%] max-w-[100%] text-greenshade bg-white bg-opacity-80 p-4 rounded-lg mx-auto">
      {/* Responsive Heading */}
      <div className="text-[1rem] md:text-[1.2rem] lg:text-[1.5rem] mb-2">
        Stay in the loop with the latest updates!
      </div>

      {/* Responsive Paragraph */}
      <p className="font-roboto text-[0.7rem] md:text-[0.8rem] lg:text-[0.9rem] text-gray-600 mb-4">
        Subscribe now to receive notifications about new events, exciting campaigns, and captivating
        blog posts directly to your inbox. Don't miss out on any important information or engaging
        content.
      </p>

      {/* Responsive Input and Button */}
      <form onSubmit={sendEmail} className="font-roboto flex flex-col md:flex-col gap-2 justify-center items-center mb-2">
        <input
          type="email"
          placeholder="Enter email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="pl-2 w-full sm:w-[16rem] outline-none focus:outline-none border-2 border-gray-300 rounded-lg py-2"
          required
        />
        <button
          type="submit"
          className="px-4 py-2 w-full sm:w-auto text-white bg-green rounded-lg hover:bg-green-600 transition-all duration-300"
        >
          Subscribe
        </button>
      </form>

      {/* Message Display */}
      {message && (
        <p className="font-roboto text-[3rem] md:text-[0.8rem] lg:text-[0.9rem] text-gray-600">
          {message}
        </p>
      )}

      {/* Responsive Footer Text */}
      <p className="font-roboto text-[0.6rem] md:text-[0.7rem] lg:text-[0.8rem] text-gray-600">
        Join our subscription list today and be the first to know!
      </p>
    </div>
  );
};

export default Subscription;
