import React, { useEffect } from "react";
import ProgressBar from "../../components/ProgressBar";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { url } from "../../data";
import { useNavigate } from 'react-router-dom';
import bglo from "../../assets/Logo/bglo.png";
import NextProject from "./NextProject";


export default function ProjectDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  console.log("project detail", id);

  const [Data, setData] = useState(null);

  const url = process.env.REACT_APP_API_URL + `/projects/${id}?populate=*`;
  const headers = {
    Authorization: "bearer" + process.env.REACT_APP_API_TOKEN,
  };
  console.log(url, "url");

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios({
        method: "get",
        url: url,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      console.log(data?.data, "blog error");

      setData(data?.data);

      console.log(Data, " Data");
    };

    fetchData();
  }, [url]);

  return (
    <>
      <div className="grid md:grid-cols-4 sm:grid-rows-2">
        {/* <div className="flex max-md:flex-col max-md:items-stretch max-md:gap-0">
          <div className="flex flex-col items-stretch w-[100%] max-md:w-full max-md:ml-0">
          <div className="flex grow flex-col items-stretch mt-20 max-md:max-w-full max-md:mt-10">
            <div className="max-md:max-w-full"> */}
        <div className="col-span-3 mt-px sm:row-span-1">
          <img
            loading="lazy"
            src={process.env.REACT_APP_API_IMAGE_URL + Data?.attributes?.right_img_2.data[0].attributes?.url
              // src={process.env.REACT_APP_API_IMAGE_URL + Data?.attributes?.right_img_2.data[0].attributes?.url}
            }
            className="object-contain object-center w-full h-full overflow-hidden mt-6"
          />
        </div>
        <div className="p-[1rem] xl:w-[100%] md:w-[100%] lg:w-[100%]  pt-20 sm:row-span-1">
          <NextProject   />
        </div>
      </div>
    </>
  );
}
