// import React from 'react';
// import { motion } from 'framer-motion';
// import organizer1 from '../../../src/assets/Designer1.jpg';
// import organizer2 from '../../../src/assets/Designer.png';
// import organizer3 from '../../../src/assets/Designer5.png';
// import organizer4 from '../../../src/assets/PR - MANAGER.png';
// import organizer5 from '../../../src/assets/web mEMBER pROFILE pICTUER.png';

// function EventOrganizer() {
//   return (
//     <div>
//     <motion.div
//     initial={{
//       x: 100,
//     }}
//     whileInView={{
//       x: 0,
//       transition: {
//         duration: 0.8,
//         bounce: 5,
//         type: "easy",
//         delay: 0.1,
//       },
//     }}
//     className={` text-center`}
//     >
//     <div className={` text-[2rem] text-greenshade`}>
//       <motion.h1
//         initial={{
//           x: 100,
//         }}
//         whileInView={{
//           x: 0,
//           transition: {
//             duration: 0.8,
//             bounce: 5,
//             type: "easy",
//             delay: 0.1,
//           },
//         }}
//       >
//         Event Organizer
//       </motion.h1>
//     </div>
//     <div className={` text-greendark`}>
//       <motion.p
//         initial={{
//           x: 100,
//         }}
//         whileInView={{
//           x: 0,
//           transition: {
//             duration: 0.8,
//             bounce: 5,
//             type: "easy",
//             delay: 0.1,
//           },
//         }}
//       >
//         Our Partners That Help with the Events
//       </motion.p>
//     </div>
//     </motion.div>
//     <div className='flex items-center ml-[30%] '>
//         <motion.img
//             viewport={{ once: true }}
//             initial={{
//               x: 0,
//               opacity: 0,
//               scale: 0,
//             }}
//             whileInView={{
//               x: 0,
//               scale: 1,
//               opacity: 1,
//               transition: {
//                 duration: 0.5,
//                 bounce: 0.1,
//                 delay: 0.6,
//                 type: "easy",
//               },
//             }}
//             src={organizer1}
//             alt="logo"
//             className="w-[15%] rounded-3xl"
//           />
//                   <motion.img
//             viewport={{ once: true }}
//             initial={{
//               x: 0,
//               opacity: 0,
//               scale: 0,
//             }}
//             whileInView={{
//               x: 0,
//               scale: 1,
//               opacity: 1,
//               transition: {
//                 duration: 0.5,
//                 bounce: 0.1,
//                 delay: 0.6,
//                 type: "easy",
//               },
//             }}
//             src={organizer2}
//             alt="logo"
//             className="w-[20%] rounded-3xl"
//           />
//                   <motion.img
//             viewport={{ once: true }}
//             initial={{
//               x: 0,
//               opacity: 0,
//               scale: 0,
//             }}
//             whileInView={{
//               x: 0,
//               scale: 1,
//               opacity: 1,
//               transition: {
//                 duration: 0.5,
//                 bounce: 0.1,
//                 delay: 0.6,
//                 type: "easy",
//               },
//             }}
//             src={organizer3}
//             alt="logo"
//             className="w-[15%] rounded-3xl "
//           />
//     </div>
//     </div>
//   );
// }

// export default EventOrganizer;

import React from 'react';
import organizer1 from '../../../src/assets/Designer1.jpg';
import organizer2 from '../../../src/assets/Designer.png';
import organizer3 from '../../../src/assets/Designer5.png';

function EventOrganizer() {
  return (
    <div>
      {/* Title Section */}
      <div className="text-center">
        <div className="text-[2rem] text-greenshade">
          <h1>Event Organizer</h1>
        </div>
        <div className="text-greendark">
          <p>Our Partners That Help with the Events</p>
        </div>
      </div>

      {/* Organizer Images Section */}
      <div className="flex items-center ml-[30%]">
        <img
          src={organizer1}
          alt="Organizer 1"
          className="w-[15%] rounded-3xl"
        />
        <img
          src={organizer2}
          alt="Organizer 2"
          className="w-[20%] rounded-3xl"
        />
        <img
          src={organizer3}
          alt="Organizer 3"
          className="w-[15%] rounded-3xl"
        />
      </div>
    </div>
  );
}

export default EventOrganizer;
