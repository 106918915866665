import React from "react";
// import RecentCardComponent from "./RecentCardComponent";
// import { url } from "../data";
import { useState, useEffect } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const NextProject = () => {
  const [Data, setData]= useState(null)
  const navigate = useNavigate();

  const url = process.env.REACT_APP_API_URL + `/projects?populate=*`;
  const headers = {
    "Authorization":"bearer" + process.env.REACT_APP_API_TOKEN
  }
  console.log(url,'url')

  useEffect(() => {
    const fetchData =  async () => {

      const {data} =   await axios({
        method: "get",
        url: url,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },

      })
      console.log(data?.data,'projects error')

      setData(data?.data  )

    console.log(Data,' Data')
  }

  fetchData()

  },[url])

  return (
    <div className="w-[100%] lg:w-[100%] max-w-[100%] p-[20px]  bg-white border-white border-2 shadow-lg  rounded-lg">
      <div className="py-[3px]">
        <h2 className="text-darkgren text-[1.5rem]">Next Projects</h2>
      </div>
      <div className="w-full h-[3px] bg-black first-letter:"></div>
      <div className="py-[20px] flex flex-col gap-[1rem]">
        {Data?.map((x) => (
           <motion.div
           viewport={{ once: true }}
           initial={{
             x: 100,
             opacity: 0,
           }}
           whileInView={{
             x: 0,
             scale: 1,
             opacity: 1,
             transition: {
               duration: 0.5,
               bounce: 0.1,
               type: "easy",
               delay: 0.6,
             },
           }}
           className="flex cursor-pointer"

           onClick = {() => {
            //  navigate(`/campaigns/${x.id}`);
            navigate("/projects/" + x.id);
             window.scrollTo(0, 0);
           }}
         >
           <div className="flex">
             <div className="pr-[1rem] pt-[.5rem] w-[25%]">
               <img alt="recent" src={process.env.REACT_APP_API_IMAGE_URL + x.attributes?.left_img_1.data[0].attributes?.url} className="rounded-lg"></img>
             </div>
             <div className="text-greenshade w-[75%]">
               <h5 className="font-sans font-bold text-lg">{x.attributes?.name}</h5>
               {/* <p className="font-sans font-semibold w-full">{x.attributes?.detail}</p> */}
             </div>
           </div>
         </motion.div>

        ))}
      </div>
    </div>
  );
};

export default NextProject;
