import { all, fork } from "redux-saga/effects";
import { watchGetBlogSaga } from "./getBlogSaga";
import { watchGetAllBlogs } from "./getBlogsSaga";
import { watchUpdateBlogSaga } from "./updateBlog";
import { watchDeleteBlogSaga } from "./deleteBlogSaga";
import { watchCreateBlogSaga } from "./createBlogSaga";

import { watchGetCampaignSaga } from "./getCampaignSaga";
import { watchGetAllCampaigns } from "./getCampaignsSaga";
import { watchUpdateCampaignSaga } from "./updateCampaign";
import { watchDeleteCampaignSaga } from "./deleteCampaignSaga";
import { watchCreateCampaignSaga } from "./createCampaingSaga";

const rootSaga = function* () {
  yield all([
    fork(watchCreateBlogSaga),
    fork(watchGetBlogSaga),
    fork(watchGetAllBlogs),
    fork(watchUpdateBlogSaga),
    fork(watchDeleteBlogSaga),
    fork(watchGetCampaignSaga),
    fork(watchGetAllCampaigns),
    fork(watchUpdateCampaignSaga),
    fork(watchDeleteCampaignSaga),
    fork(watchCreateCampaignSaga),
  ]);
};

export default rootSaga;
