import React, { useState } from "react";
import popimg from "../../assets/website under construction 90.png";

const Popup = () => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
  };
  const originalText =
  '';

const [slicedText, setSlicedText] = useState(originalText);

const handleSliceText = () => {
  // You can adjust the start and end indices based on your requirements
  const sliced = originalText.slice(0, 50);
  setSlicedText(sliced);
};

  return (
    <>
      {isOpen && (
//         <div className="fixed z-10 top-[20%] left-[10%] w-[80%] h-auto flex items-center justify-center bg-black bg-opacity-0">
//           <div className="bg-white p-8 rounded-lg">
//           <button
//               className="mt-4 p-2 bg-blue-500 text-green rounded-md"
//               onClick={handleClose}
//             >
//               Close
//             </button>

//             <div className="bg-zinc-300 px-14 py-12 max-md:px-5">
//       <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
//         <div className="flex flex-col items-stretch w-[36%] max-md:w-full max-md:ml-0">
//           <img
//             src={popimg}
//             alt="image"
//             className=" aspect-auto object-contain object-center w-auto h-svh overflow-hidden my-auto max-md:mt-10"
//           />
//         </div>
//         <div className="flex flex-col items-stretch w-[64%] ml-5 max-md:w-full max-md:ml-0">
//               <div className="text-black text-xl mt-10 max-md:max-w-full max-md:mt-10">
//                 <br />
//                 Witness firsthand the transformative impact of Jesus Christ's teachings as they uplift and support nations throughout the continent. Through prayer and devotion, we aim to rally the African community and seek divine blessings for our mission.
// ae beatae laudantium
//                 officia voluptas sit, accusantium adipisci.
//                 <br />

//                 <br />
//   This unique tour promises a deep dive into the rich history and spiritual significance of each location, fostering a profound connection with our faith. Stay tuned for updates and mark your calendars for an unforgettable journey of faith, unity, and enlightenment with the Constantine African Foundation! 

//                 <br />
//                 <br />
//                 <br />
//                 <br />
//               </div>
//             </div>
//           </div>
//          </div>
          
//            </div>
//          </div>

<div className="fixed z-10 top-[20%] w-full h-96 flex items-center justify-center bg-black bg-opacity-0">
{/* <div className="bg-white p-8 rounded-lg overflow-y-auto"> */}
  <button
    className=" mb-72 bg-darkgren hover:bg-white text-white hover:text-darkgren py-2 px-4 border border-darkgren hover:border-darkgren rounded"
    onClick={handleClose}
  >
    Close
  </button>
  <img
    src={popimg}
    alt="image"
    className="object-contain object-center w-96 h-5rem overflow-hidden my-auto max-md:mt-10"
  />
{/* </div> */}
</div>


      )}
    </>
  );
};

export default Popup;