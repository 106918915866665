import { put, takeLatest } from "redux-saga/effects"
import axios from "axios"
import { deleteBlogAction, deleteBlogFailureAction, deleteBlogSuccessAction } from "../slices/slice";

function* deleteBlogSaga() {
    try {
        yield put(deleteBlogAction());
        const response = axios.delete("");
        yield put(deleteBlogSuccessAction(response.data));
    } catch(error) {
        yield put(deleteBlogFailureAction(error))
    }
}

export function* watchDeleteBlogSaga() {
    yield takeLatest('DELETE_BLOG_BY_ID', deleteBlogSaga)
}