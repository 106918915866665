import axios from "axios";
import { put, takeLatest } from "redux-saga/effects";
import { createBlogAction, createBlogFailureAction, createBlogSuccessAction } from "../slices/slice";

function* createBlogSaga({payload}) {
    try {
        yield put(createBlogAction());
        const response = yield axios.post("");
        yield put(createBlogSuccessAction(response.data));
    } catch(error) {
        yield put(createBlogFailureAction(error));
    }
} 

export function* watchCreateBlogSaga() {
    yield takeLatest('CREATE_BLOG', createBlogSaga);
}