import React, { useState } from "react";
import DontationBacground from "../assets/Constantine_Donate_rm.png";
import { useNavigate } from "react-router-dom";

const Donation = () => {

  const navigate = useNavigate();
  return (
<div className="relative flex flex-col lg:flex-row w-full h-full overflow-hidden">

    {/* Image */}
    <div className="lg:p-14 md:p-12 p-0">
      <h1 className=" text-5xl text-greenshade pb-5">Get In Touch With us</h1>
      <p className=" text-darkgren p-10">Thank you for reaching out! Whether you have general inquiries, seek potential partnerships, are interested in volunteering, 
        or aspire to become an ambassador, we welcome your communication. Our team is dedicated to providing prompt and informative 
        responses. Please complete the form below with your details, and we'll make sure to get back to you as soon as possible.</p>
    </div>
    <img
    src={DontationBacground}
    alt="donation"
    className="w-[40%] h-[50%] ml-48 pr-10 pt-3"
    onClick={
      () => {
        navigate("/login");
        window.scrollTo(0, 0);
      }
    }
  />
</div>
    
  );
};

export default Donation;
