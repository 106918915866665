import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 

const DonorList = () => {
  const navigate = useNavigate();
  const [Data, setData] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);

  const url = process.env.REACT_APP_API_URL + `/donors?populate=*`;
  const headers = {
    "Authorization": "Bearer " + process.env.REACT_APP_API_TOKEN 
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios({
          method: "get",
          url: url,
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          },
        });
        setData(data?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [url]);

  useEffect(() => {
    if (Data) {
      const total = Data.reduce((acc, donor) => acc + donor.attributes.amount, 0);
      setTotalAmount(total);
    }
  }, [Data]);

  const progressBarWidth = Math.min((totalAmount / 200000) * 100, 100);

  return (
    <div className="w-full max-w-[300px] h-auto p-[10px] bg-white border-white border-2 shadow-lg rounded-lg sticky mb-4">
      <div className="flex flex-col">
        <button 
          className="font-sans w-full bg-buttongreen px-6 py-1 rounded-md text-white self-end"
          onClick={() => {
            navigate("/donate");
            window.scrollTo(0, 0);
          }}
        >
          Donate
        </button>
      </div>
      <div className="w-full h-5 rounded-2xl bg-orange overflow-hidden my-3">
        <div
          className="h-full bg-greendark"
          style={{ width: `${progressBarWidth}%` }}
        ></div>
      </div>
      <div>
        <h1 className=' font-serif font-bold '>${totalAmount} raised of $200,000 goal</h1>
      </div>
      <div className="pt-3 pb-[3%]">
        <h2 className="text-greenshade text-[1.5rem]">List of Donors</h2>
      </div>
      <div className="pb-[20px] flex gap-1 h-[19rem] overflow-y-auto">
        <div className="w-full cursor-pointer">
          {Data?.map(x => (
            <div key={x.id} className="text-greenshade">
              <h5 className="font-sans font-bold text-lg">
                {x.attributes?.firstName} {x.attributes?.lastName} {x.attributes?.amount} $
              </h5>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DonorList;
