import CampainList from "../../components/campaign/campainList";
import Header from "../../components/campaign/detailHeader";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const Campains = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const blog = useSelector((state) => state.Blog.blog);

  console.log("hello");

  const [Data, setData] = useState(null);

  const url = process.env.REACT_APP_API_URL + `/campaigns/${id}?populate=*`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios({
          method: "get",
          url: url,
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`, // Add a space after "Bearer"
          },
        });

        console.log(data?.data, "blog data");

        setData(data?.data);
        console.log(Data, "Data");
      } catch (error) {
        console.error("Error fetching blog data:", error.message);
      }
    };

    fetchData();
  }, [url]);

  return (
    <div>
      <Header Data={Data} />
      <CampainList  />
      
    </div>
  );
};

export default Campains;
