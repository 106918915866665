import { combineReducers } from "redux";
import AuthReduce from "./Auth";
import blogReducer from "../../slices/slice";
import blogsReducer from "../../slices/getBlogsSlice";
import campaignReducer from "../../slices/campaignSlice";
import campaignsReducer from "../../slices/getCampaingsSlice";

const rootReducer = combineReducers({
  AuthReduce: AuthReduce,
  Blog: blogReducer,
  Blogs: blogsReducer,
  Campaign: campaignReducer,
  Campaigns: campaignsReducer,
});
export default rootReducer;
