import { createSlice } from "@reduxjs/toolkit";


const allBlogsInitalState = {
    allBlogs: {
        isLoading: false,
        data: [],
        errors: ""
    }
}

export const getAllBlogsSlice = createSlice({
    name: 'all_Blogs',
    initialState: allBlogsInitalState,
    reducers: {
        getAllBlogsAction: (state) => {
            state.allBlogs.isLoading = true;
            state.allBlogs.errors = ""
        },
        getAllBlogsSuccessAction: (state, action) => {
            state.allBlogs.isLoading = false;
            state.allBlogs.data = action.payload;
        },
        getAllBlogsFailureAction: (state, action) => {
            state.allBlogs.isLoading = false;
            state.allBlogs.errors = action.payload;
        }
    }
})


export const {
    getAllBlogsAction,
    getAllBlogsSuccessAction,
    getAllBlogsFailureAction
} = getAllBlogsSlice.actions;

export default getAllBlogsSlice.reducer;