import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";

const LatestEventDetail = () => {

    const {id} = useParams()
    const navigate = useNavigate();
    const [Data, setData] = useState(null);
  
    const url = process.env.REACT_APP_API_URL + `/events/${id}?populate=*`;
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const { data } = await axios({
            method: "get",
            url: url,
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`, // Add a space after "Bearer"
            },
          });
  
          console.log(data?.data, "event data");
  
          setData(data?.data);
          console.log(Data, "Data");
        } catch (error) {
          console.error("Error fetching event data:", error.message);
        }
      };
  
      fetchData();
    }, [url]);

    return (
        <>
        {/* <h1>
            Hello this is latest event detail
        </h1>
        <img 
            className="rounded-t-lg" 
            src={process.env.REACT_APP_API_IMAGE_URL + Data?.attributes?.imageURL?.data[0].attributes?.url}
            alt="" /> */}
    <div className="flex flex-col p-28 pb-5 bg-darkgren">
      <div className="items-center px-16 pt-6 pb-20 w-full lg:text-5xl md:text-5xl text-3xl text-center text-white bg-zinc-300 max-md:px-5 max-md:max-w-full">
      {Data?.attributes?.name}
      </div>
      <div className="flex gap-5 justify-between px-px mt-2.5 w-full text-2xl text-center text-white max-md:flex-wrap max-md:max-w-full">
        <div className="border-greenlight rounded-3xl justify-center items-center px-16 py-12 bg-zinc-300 max-md:px-5 max-md:max-w-full border-double border-4 border-sky-500">
          Start From {Data?.attributes?.start_date}
        </div>
        <div className="border-greenlight rounded-3xl justify-center items-center px-16 py-12 bg-zinc-300 max-md:px-5 max-md:max-w-full border-double border-4 border-sky-500">
          To {Data?.attributes?.end_date}
        </div>
      </div>
      <div className="mt-3.5 w-full max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
          <div className="flex flex-col w-[35%] max-md:ml-0 max-md:w-full">
            {/* <div className="self-stretch m-auto max-w-full bg-zinc-300 h-[472px] w-[503px] max-md:mt-10" /> */}
            <img
                loading="lazy"
                src={`${process.env.REACT_APP_API_IMAGE_URL + Data?.attributes?.imageURL?.data[0].attributes?.url}`}
                className="self-stretch m-auto max-w-full bg-zinc-300 h-[472px] w-[503px] max-md:mt-10"
            />
          </div>
          <div className="flex flex-col ml-5 w-[65%] max-md:ml-0 max-md:w-full border-3xl border-white">
            <div className="justify-center pt-48 pr-4 pb-12 pl-0 w-full lg:text-3xl md:text-3xl text-1xl text-white  bg-zinc-300 max-md:pt-10 max-md:pl-5 max-md:max-w-full">
            {Data?.attributes?.description}
            </div>
          </div>
        </div>
      </div>
    </div>
        </>
    )
}

export default LatestEventDetail;