import React, { useEffect, useState } from "react";
import Logo from "../assets/ConstantionLogoColor.png";
import { Link, useLocation } from "react-router-dom";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

const NavBar = () => {
  const [Active, setActive] = useState("home");
  const [NavWhite, setNavWhite] = useState(false);
  const location = useLocation();
  useEffect(() => {
    var currentLocation =
      location.pathname.split("/")[1] === ""
        ? "home"
        : location.pathname.split("/")[1];
    setActive(currentLocation);
    window.addEventListener("scroll", (e) => {
      if (window.pageYOffset >= 452) {
        setNavWhite(true);
      } else {
        setNavWhite(false);
      }
    });
  }, [location.pathname]);
  return (
    <>
      <div
        className={` flex fixed top-0 left-0 w-full font-roboto md:px-12 px-6 justify-between z-[60] text-white h-[2.5rem] ${
          NavWhite
            ? "bg-white pb-2 drop-shadow-[0_5px_5px_rgba(0,0,0,0.25)] "
            : ""
        }`}
      >
        <div className="h-full ">
          <img
            alt="logo"
            className={`${NavWhite ? " h-full" : "h-full"} object-cover`}
            src={Logo}
          />
        </div>
        <div className="md:flex  hidden gap-2 justify-end items-start">
        <Link
          className={`bg-green rounded-b-lg   ${
            NavWhite
              ? Active === "home"
                ? " bg-white h-full  text-green"
                : " h-full"
              : Active === "home"
              ? "h-[200%] text-green bg-white"
              : "h-full "
          } flex items-end  h-1/2 p-2.5 w-[5rem] text-[0.8rem] justify-center  text-center`}
          onClick={() => window.scrollTo(0, 0)}
          to={"/home"}
          >
          Home
        </Link>

        <Link
          className={`bg-green rounded-b-lg  ${
            NavWhite
              ? Active === "about"
                ? " bg-white h-full  text-green"
                : " h-full"
              : Active === "about"
              ? "h-[200%] text-green bg-white"
              : "h-full "
          } flex items-end  h-1/2 p-2.5 w-[5rem] text-[0.8rem] justify-center  text-center`}
          onClick={() => window.scrollTo(0, 0)}
          to={"/about"}
          >
          About Us
        </Link>

        <Link
          className={`bg-green rounded-b-lg ${
            NavWhite
              ? Active === "projects"
                ? " bg-white h-full  text-green"
                : " h-full"
              : Active === "projects"
              ? "h-[200%] text-green bg-white"
              : "h-full "
          } flex items-end  h-1/2 p-2.5 w-[5rem] text-[0.8rem] justify-center  text-center`}
          onClick={() => window.scrollTo(0, 0)}
          to={"/projects"}
          >
          Projects
        </Link>

        <Link
          className={`bg-green rounded-b-lg  ${
            NavWhite
              ? Active === "campaings"
                ? " bg-white h-full  text-green"
                : " h-full"
              : Active === "campaings"
              ? "h-[200%] text-green bg-white"
              : "h-full "
          } flex items-end  h-1/2 p-2.5 w-[5rem] text-[0.8rem] justify-center  text-center`}
          onClick={() => window.scrollTo(0, 0)}
          to={"/campaings"}
          >
          Campaigns
        </Link>

        <Link
          className={`bg-green rounded-b-lg  ${
            NavWhite
              ? Active === "event"
                ? " bg-white h-full  text-green"
                : " h-full"
              : Active === "event"
              ? "h-[200%] text-green bg-white"
              : "h-full "
          } flex items-end  h-1/2 p-2.5 w-[5rem] text-[0.8rem] justify-center  text-center`}
          onClick={() => window.scrollTo(0, 0)}
          to={"/event"}
          >
          Events
        </Link>

        <Link
          className={`bg-green rounded-b-lg  ${
            NavWhite
              ? Active === "shop"
                ? " bg-white h-full  text-green"
                : " h-full"
              : Active === "shop"
              ? "h-[200%] text-green bg-white"
              : "h-full "
          } flex items-end  h-1/2 p-2.5 w-[5rem] text-[0.8rem] justify-center  text-center`}
          onClick={() => window.scrollTo(0, 0)}
          to={"/shop"}
          >
          shop
        </Link>

        <Link
          className={`bg-green rounded-b-lg   ${
            NavWhite
              ? Active === "blogs"
                ? " bg-white h-full  text-green"
                : " h-full"
              : Active === "blogs"
              ? "h-[200%] text-green bg-white"
              : "h-full "
          } flex items-end  h-1/2 p-2.5 w-[5rem] text-[0.8rem] justify-center  text-center`}
          onClick={() => window.scrollTo(0, 0)}
          to={"/blogs"}
          >
          Blogs
        </Link>

        <Link
          className={`bg-green rounded-b-lg  ${
            NavWhite
              ? Active === "contact"
                ? " bg-white h-full  text-green"
                : " h-full"
              : Active === "contact"
              ? "h-[200%] text-green bg-white"
              : "h-full "
          } flex items-end  h-1/2 p-2.5 w-[5rem] text-[0.8rem] justify-center  text-center`}
          onClick={() => window.scrollTo(0, 0)}
          to={"/contact"}
          >
          Contact
        </Link>

        <Link onClick={() => window.scrollTo(0, 0)} to={"/donate"}>
          <div className="flex mb-1 gap-2 h-full items-center cursor-pointer hover:scale-110 px-3 py-1 rounded-lg bg-buttongreen text-white text-[0.9rem] xl:text-[1.2rem] ">
          <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="75 0 512 512" height="1em" width="1.5em" xmlns="http://www.w3.org/2000/svg">
          <path d="M256 448c-23.6 0-46-9-62.8-25.6C115.6 330.4 56 259.5 56 176 56 88.3 132.3 12 220 12c55.1 0 104.5 28.2 133.6 73.4C339.5 40.2 388.9 12 444 12 531.7 12 608 88.3 608 176c0 83.5-59.6 154.4-137.2 246.4C302 439 279.6 448 256 448z"/>
          </svg>
          Donate Now
          </div>
        </Link>
        </div>
        <div className=" relative group md:hidden flex items-center ">
          <div className=" cursor-pointer text-primary" onClick={() => {}}>
            <MenuOutlinedIcon />
          </div>
          <div className="absolute right-0 top-[70%] items-center hidden group-hover:flex flex-col gap-1 ">
            <Link onClick={() => window.scrollTo(0, 0)} to={"/home"}>
              <div
                className={`bg-green rounded-xl  hover:scale-110 hover:scale-110${
                  Active === "home" ? "h-full text-white" : "h-1/2 "
                }  ${
                  NavWhite ? " h-full" : ""
                }flex items-end  p-2.5 w-[5rem] text-[0.8rem] justify-center text-center`}
              >
                Home
              </div>
            </Link>
            <Link onClick={() => window.scrollTo(0, 0)} to={"/campaings"}>
              <div
                className={`bg-green rounded-xl  hover:scale-110 hover:scale-110${
                  Active === "campaings" ? "h-full text-white" : "h-1/2 "
                } ${
                  NavWhite ? " h-full" : ""
                } flex items-end  h-1/2 p-2.5 w-[5rem] text-[0.8rem] justify-center  text-center`}
              >
                Campaings
              </div>
            </Link>

            <Link onClick={() => window.scrollTo(0, 0)} to={"/event"}>
              <div
                className={`bg-green rounded-xl  hover:scale-110 hover:scale-110${
                  Active === "event" ? "h-full text-white" : "h-1/2 "
                }${
                  NavWhite ? " h-full" : ""
                } flex items-end  h-1/2 p-2.5 w-[5rem] text-[0.8rem] justify-center  text-center`}
              >
                Events
              </div>
            </Link>

            <Link onClick={() => window.scrollTo(0, 0)} to={"/projects"}>
              <div
                className={`bg-green rounded-xl  hover:scale-110 hover:scale-110${
                  Active === "projects" ? "h-full text-white" : "h-1/2 "
                }${
                  NavWhite ? " h-full" : ""
                } flex items-end  h-1/2 p-2.5 w-[5rem] text-[0.8rem] justify-center  text-center`}
              >
                Projects
              </div>
            </Link>

            <Link onClick={() => window.scrollTo(0, 0)} to={"/shop"}>
              <div
                className={`bg-green rounded-xl  hover:scale-110 hover:scale-110${
                  Active === "shop" ? "h-full text-white" : "h-1/2 "
                }${
                  NavWhite ? " h-full" : ""
                } flex items-end  h-1/2 p-2.5 w-[5rem] text-[0.8rem] justify-center  text-center`}
              >
                Shop
              </div>
            </Link>

            <Link onClick={() => window.scrollTo(0, 0)} to={"/blogs"}>
              <div
                className={`bg-green rounded-xl  hover:scale-110 hover:scale-110${
                  Active === "blogs" ? "h-full text-white" : "h-1/2 "
                }${
                  NavWhite ? " h-full" : ""
                } flex items-end  h-1/2 p-2.5 w-[5rem] text-[0.8rem] justify-center  text-center`}
              >
                Blogs
              </div>
            </Link>

            <Link onClick={() => window.scrollTo(0, 0)} to={"/about"}>
              <div
                className={`bg-green rounded-xl  hover:scale-110 hover:scale-110${
                  Active === "about" ? "h-full text-white" : "h-1/2 "
                }${
                  NavWhite ? " h-full" : ""
                } flex items-end  h-1/2 p-2.5 w-[5rem] text-[0.8rem] justify-center  text-center`}
              >
                About Us
              </div>
            </Link>

            <Link onClick={() => window.scrollTo(0, 0)} to={"/contact"}>
              <div
                className={`bg-green rounded-xl  hover:scale-110  ${
                  Active === "contact" ? "h-full text-white" : "h-1/2 "
                }${
                  NavWhite ? " h-full" : ""
                } flex items-end  h-1/2 p-2.5 w-[5rem] text-[0.8rem] justify-center  text-center`}
              >
                Contact
              </div>
            </Link>

            <Link onClick={() => window.scrollTo(0, 0)} to={"/login"}>
          <div className="flex mb-1 gap-2 h-full items-center cursor-pointer hover:scale-110 px-3 py-1 rounded-lg bg-buttongreen text-white text-[0.9rem] xl:text-[1.2rem] ">
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207" />
            </svg>
            Donate Now
          </div>
          </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
