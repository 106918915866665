// import React, { useState, useEffect } from 'react';

// const CookieConsent = () => {
//   const [showBanner, setShowBanner] = useState(false);

//   useEffect(() => {
//     const consent = localStorage.getItem('cookieConsent');
//     if (!consent) {
//       setShowBanner(true);
//     }
//   }, []);

//   const handleAccept = () => {
//     localStorage.setItem('cookieConsent', 'true');
//     setShowBanner(false);
//     setCookiesAndCache();
//   };

//   const handleDecline = () => {
//     setShowBanner(false);
//   };

//   const setCookiesAndCache = () => {
//     document.cookie = "username=John Doe";
//     // Additional cookies and cache settings can be added here
//   };

//   if (!showBanner) return null;

//   return (
//     <div style={styles.banner} className=' z-50 h-[5rem]'>
//       <p>We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.</p>
//       <button onClick={handleAccept} style={styles.button}>Accept</button>
//       <button onClick={handleDecline} style={styles.button}>Decline</button>
//     </div>
//   );
// };

// const styles = {
//   banner: {
//     position: 'fixed',
//     bottom: 0,
//     left: 0,
//     right: 0,
//     background: '#000',
//     color: '#fff',
//     padding: '10px',
//     textAlign: 'center',
//   },
//   button: {
//     margin: '0 5px',
//     padding: '5px 10px',
//     cursor: 'pointer',
//   },
// };

// export default CookieConsent;



import React, { useState, useEffect } from 'react';

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowBanner(false);
    setCookiesAndCache();
  };

  const handleDecline = () => {
    setShowBanner(false);
  };

  const setCookiesAndCache = () => {
    document.cookie = "username=John Doe";
    // Additional cookies and cache settings can be added here
  };

  if (!showBanner) return null;

  return (
    <div style={styles.banner} className=' z-50 h-[10rem]'>
      <p style={styles.text}>
        We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.
      </p>
      <div style={styles.buttonContainer}>
        <button onClick={handleAccept} style={styles.button}>Accept</button>
        <button onClick={handleDecline} style={styles.button}>Decline</button>
      </div>
    </div>
  );
};

const styles = {
  banner: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    background: '#000',
    color: '#fff',
    padding: '10px 20px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999,
  },
  text: {
    fontSize: '1rem',
    marginBottom: '10px',
    maxWidth: '90%',
  },
  buttonContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    justifyContent: 'center',
  },
  button: {
    background: '#00a166',
    color: '#fff',
    border: 'none',
    padding: '10px 15px',
    cursor: 'pointer',
    borderRadius: '5px',
    fontSize: '0.9rem',
    transition: 'background 0.3s ease',
  },
  '@media (min-width: 768px)': {
    banner: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '10px 50px',
    },
    text: {
      fontSize: '1.1rem',
      marginBottom: 0,
    },
    button: {
      padding: '10px 20px',
      fontSize: '1rem',
    },
  },
  '@media (min-width: 1024px)': {
    text: {
      fontSize: '1.2rem',
    },
  },
};

export default CookieConsent;
