import React from 'react';
import Donation from "../../Contactus/Donation";
import x from "../../../assets/Logo/bglo.png";
import NextBlog from "./NextBlog";

const Body = ({Data}) => {
  return (
    <>
        <div className="flex md:grid-cols-4 sm:grid-rows-2 bg-darkgren">
          <div className=' col-span-3 pt-20 sm:row-span-1'>
          <div className=" w-full text-white text-5xl self-center max-w-full pt-10 pb-3 px-16 items-start max-md:pl-6 max-md:pr-5">
              {Data?.attributes?.title}
          </div>
          <div className="w-full mt-9 max-md:max-w-full">
            <div className="gap-5 flex max-md:flex-col max-md: max-md:gap-0">
              <div className="flex flex-col  w-[30%] max-md:w-full max-md:ml-0">
              <img
                loading="lazy"
                src={`${process.env.REACT_APP_API_IMAGE_URL + Data?.attributes?.imageUrl.data[0].attributes?.url}`}
                className="object-contain  max-md:mt-10 aspect-w-1 aspect-h-1"
              />
              </div>
              <div className=" bg-darkgren flex flex-col  w-[61%] ml-5 max-md:w-full max-md:ml-0">
                <div className="text-white text-2xl bg-zinc-300 grow justify-center  w-full pl-8 pr-14 py-12 max-md:max-w-full max-md:mt-10 max-md:px-5">
                {Data?.attributes?.right_desc}
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="p-[1rem] xl:w-[100%] md:w-[100%] lg:w-[100%] pt-28 sm:row-span-1">
            <NextBlog   />
          </div>
          </div>

    </>
  );
};

export default Body;
