import React, { useState } from "react";
import LoginForm from './Login';
import SignUpForm from './Signup'; 
import Logo from '../assets/Logo/ConstantionLogoColor.png'; // Adjust the path to your Logo file

const Auth = () => {
    const [isLogin, setIsLogin] = useState(true); // Added state for isLogin
    
    return (
        <div className="bg-gradient-to-tr from-darkgreen to-greenlight flex flex-col items-center justify-center min-h-screen md:py-2">
            <main className="flex items-center w-full px-2 md:px-20">
                <div className="hidden md:inline-flex flex-col flex-1 space-y-1">
                    <img
                        alt='waving tree'
                        src={Logo}
                        className='w-[70%]'
                    />
                </div>
                {
                    isLogin ? (
                        <LoginForm setIsLogin={setIsLogin} /> // Pass setIsLogin to LoginForm
                    ) : (
                        <SignUpForm setIsLogin={setIsLogin} /> // Pass setIsLogin to SignUpForm
                    )
                }
            </main>
        </div>
    );
}

export default Auth;
