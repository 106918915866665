//import React, { useEffect, useState } from "react";
//import { motion } from "framer-motion";
import React, { useState } from "react";
import PurposeSlideFromBottom from "./PurposeSlide";
import Model from "react-modal";
import WhoWeAre from "./WhoWeAre";
import ProgressBar from "../../components/ProgressBar";
import asset1 from "../../assets/Constantine About us Header.png"
import founder from "../../assets/Designer.png"
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Map from "../../assets/Map/1x/Asset13.png";

export default function AboutUs() {
  
  const [isVisible, setVisible] = useState(false);
  const navigate = useNavigate();


  return (
    <div>
<div className="relative lg:h-[50rem] md:h-[35rem] h-[18rem] w-full overflow-hidden flex items-end" id="header">
  <img
    src={asset1}
    alt="header"
    className="absolute inset-0 -z-10 w-full object-cover object-right md:object-center"
  />
</div>

      <div className="mt-[3rem] mb-[3rem]">
        <div className="text-center">
          <h1 className="text-[5rem] font-thin font-Culpa">Together</h1>
        </div>
        <div className="text-center">
          <h1 className="text-[5rem] font-thin font-Culpa">We Change</h1>
        </div>
      </div>

      <hr className="border-t-2 border-b-1 border-black w-[70%] ml-[15%] mr-[15%]" />

      <PurposeSlideFromBottom />

      <div className="w-full p-10">
          <p className="text-greenshade text-center text-[2rem] md:text-[2.5rem] lg:text-[3rem] mb-5">
            Who We Are?
          </p>
          <p className="xl:text-1.3rem text-1rem font-roboto mb-5">
            In 2023, Mr. George Constantine established the CONSTANTINE AFRICAN FOUNDATION as a catalyst for positive change throughout Africa. The foundation focuses on Algacultures, handicrafts such as carpentry and other crafts, education, healthcare, and economic empowerment to address challenges faced by African communities. By establishing vocational training programs, scholarships, and supporting small-scale farmers and artisans, Mr. Constantine uplifts individuals and contributes to regional development. His relentless dedication and inspiring leadership inspire others to join in creating a brighter future where education, healthcare, and economic opportunities are accessible to all in Africa.
          </p>
                      <button
                        id="WhoWeAre"
                        className="px-3 py-1 bg-buttongreen text-white rounded-lg outline-none focus:outline-none hover:scale-105"
                        onClick={() => setVisible(true)}
                      >
                        read more
                      </button>
                    {/* </div>   */}

                      <div>
                        <Model
                          style={{
                            zIndex: 50,
                          }}
                          isOpen={isVisible}
                          onRequestClose={() => setVisible(false)}
                        >
                          <WhoWeAre />
                          <aboutPopup />
                        </Model>

                        {/* <aboutPopup /> */}

                        </div>  
        </div>

      <div>
        <div className="flex justify-center items-center flex-wrap mb-[6rem] mt-[6rem]">
          <h1 className="w-full text-center text-[2.5rem] text-greenshade font-bold">
            Our Founders
          </h1>
          <h5 className="w-full text-center text-[1rem] font-bold text-green">
            What we do is not for us but for the world
          </h5>
        </div>

        <div className="flex flex-col items-right sm:flex-row ml-[10%] mr-[10%] w-auto ">

        <div className="flex flex-col ml-2 sm:w-2/3 md:w-1/2 p-3 item-left left-[0rem] ">
          <h1 className="mb-[2rem] text-center sm:text-left text-[1.5rem] font-bold text-orange">
            The Founder
          </h1>
          <p className="mb-[2rem] text-center sm:text-left font-sans font-bold w-[100%] " >
            "Embark on a transformation journey with the Constantine Africa Foundation, 
            led by visionary George Constantine. Our mission: spark change in Africa. 
            Empower the unemployed through vocational training, from agriculture to medicine.
            Your donation seed secure employment, funding workshop, clinics and institutes. 
            Education is paramount focusing on nursing, midwifery, special needs and church music. 
            What sets us apart? Sending exceptional individuals aboard, enriching Africa with invaluable Knowledge. 
            Join the Constantine Charitable Foundation, holistic force shaping a bright future. 
            Support our vision, believe in change, and donate today for a better Africa."
          </p>
        </div>

        <img
          src={founder}
          alt="founder"
          className="left-[-10%] w-[] sm:w-1/4 md:w-1/3 p-2 self-center"
        />
        </div>

        {/* <div>
          <div className="flex justify-center items-center flex-wrap mt-[5rem] mb-[5rem]">
            <h1 className="w-full text-center text-[2.5rem] text-greenshade font-bold">
              Countries We Are Active
            </h1>
            <h5 className="w-full text-center text-[1rem] font-bold text-green">
              Parts of the world we are actively working on
            </h5>
          </div>
          <div className="flex flex-col items-center sm:flex-row  m-[10%] mt-[5%] sm:m-[2%] mb-[2rem]">
            <div className="w-1/2 sm:w-1/2 md:w-1/2 p-3 text-center items-center md:p[0]">
              <img
                // src="/assets/Map/1x/Asset13.jpg"
                src={Map}
                alt="highlight map of countries we are working"
                className="lg:h-[28rem] lg:w-[100%] md:h-[28rem] md:w-[100%] h-[100%] w-[100%]"
              />
            </div>
            <div className="flex flex-col ml-4 sm:w-2/3 md:w-2/3 p-3">
              <h1 className="mb-[2rem] text-center sm:text-left text-[1.5rem] font-bold text-greenshade">
                Our projects till now
              </h1>
              <p className="mb-[2rem] text-center sm:text-left font-sans font-bold">
              Our Journey of Hope and Transformation: 
              Empowering Africa through Positive Change From the vibrant shores of Uganda to the bustling heart of Addis Ababa, 
              our journey has been fueled by an unwavering passion for positive change. 
              With each step we take, we strive to tackle the critical challenges that hinder Africa's progress and f oster sustainable development, 
              leaving an indelible mark on the communities we serve. 
              Our vision extends far beyond borders, encompassing the entirety of this remarkable continent. 
              With faith in our mission and the unwavering support of our partners, we aspire to continue our journey of empowerment, 
              transforming the lives of all Africans through the power of our foundation. 
              Join us as we embark on this transformative odyssey, together illuminating a brighter future for Africa.
              </p>
              <ProgressBar progress={50} />
            </div>
          </div>
        </div> */}
        <div>
  <div className="flex flex-col justify-center items-center flex-wrap mt-20 mb-20 sm:mt-[5rem] sm:mb-[5rem]">
    <h1 className="w-full text-center text-[2rem] sm:text-[2.5rem] text-greenshade font-bold">
      Countries We Are Active
    </h1>
    <h5 className="w-full text-center text-[0.875rem] sm:text-[1rem] font-bold text-green">
      Parts of the world we are actively working on
    </h5>
  </div>
  
  <div className="flex flex-col sm:flex-row items-center m-[5%] mt-[5%] sm:mt-[2%] sm:m-[2%] mb-[2rem]">
    <div className="w-full sm:w-1/2 md:w-1/2 p-3 text-center items-center md:p-0">
      <img
        // src="/assets/Map/1x/Asset13.jpg"
        src={Map}
        alt="highlight map of countries we are working"
        className="w-full h-auto max-h-[18rem] sm:max-h-[28rem]"
      />
    </div>
    
    <div className="flex flex-col mt-5 sm:mt-0 sm:ml-4 w-full sm:w-2/3 md:w-2/3 p-3">
      <h1 className="mb-6 sm:mb-[2rem] text-center sm:text-left text-[1.25rem] sm:text-[1.5rem] font-bold text-greenshade">
        Our projects till now
      </h1>
      <p className="mb-6 sm:mb-[2rem] text-center sm:text-left font-sans font-bold text-[0.875rem] sm:text-base">
        Our Journey of Hope and Transformation: Empowering Africa through Positive Change. 
        From the vibrant shores of Uganda to the bustling heart of Addis Ababa, our journey has been fueled by an unwavering passion for positive change. 
        With each step we take, we strive to tackle the critical challenges that hinder Africa's progress and foster sustainable development, 
        leaving an indelible mark on the communities we serve. 
        Our vision extends far beyond borders, encompassing the entirety of this remarkable continent. 
        With faith in our mission and the unwavering support of our partners, we aspire to continue our journey of empowerment, 
        transforming the lives of all Africans through the power of our foundation. 
        Join us as we embark on this transformative odyssey, together illuminating a brighter future for Africa.
      </p>
      <div className="flex justify-center sm:justify-start">
        <ProgressBar progress={50} />
      </div>
    </div>
  </div>
</div>

      </div>
    </div>
  );
}
