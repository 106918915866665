import { Store } from "../Store";
import MessageBox from "../components/MessageBox";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { FaMinusCircle, FaPlusCircle, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { getError } from "../utils";
import { slugify } from "../../utils/slugify";

function CartScreen() {
  const { state } = useContext(Store);
  const navigate = useNavigate();
  const { dispatch: ctxDispatch } = useContext(Store);
  const { cart: { cartItems } } = state;

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await axios.get(process.env.REACT_APP_API_URL + `/catagories`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          }
        });
        setCategories(data);
      } catch (err) {
        toast.error(getError(err));
      }
    };
    fetchCategories();
  }, []);

  const updateCartHandler = async (item, quantity) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/products/${item.id}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        }
      });
      if (data.countInStock < quantity) {
        window.alert("Sorry. Product is out of stock");
        return;
      }
      ctxDispatch({
        type: "CART_ADD_ITEM",
        payload: { ...item, quantity },
      });
    } catch (error) {
      toast.error(getError(error));
    }
  };

  const removeItemHandler = (item) => {
    ctxDispatch({ type: "CART_REMOVE_ITEM", payload: item });
  };

  const checkoutHandler = () => {
    // navigate("/login?redirect=/shipping");
    navigate("/auth");
  };

  return (
    <div className="mb-10">
      <h1 className={`text-[2rem] mb-10 font-roboto text-green`}>
        Shopping Cart
      </h1>
      <div className="flex flex-col md:flex-row gap-5 items-start">
        <div className="md:w-8/12 bg-white rounded-lg">
          {cartItems.length === 0 ? (
            <MessageBox>
              Cart is empty.{" "}
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to="/shop"
              >
                Go Shopping
              </Link>
            </MessageBox>
          ) : (
            <ul className="divide-y divide-gray">
              {cartItems.map((item, index) => (
                <li
                  key={index}
                  className="p-4 flex items-start justify-between"
                >
                  <div className="md:w-1/4">
                    <img
                      src={process.env.REACT_APP_API_IMAGE_URL + item.attributes?.imageURL.data[0].attributes?.url}
                      alt={item.attributes?.name}
                      className="w-16 h-16 rounded-full object-cover"
                    />
                    <Link
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                      to={`/shop/products/${slugify(item.attributes?.name)}`}
                      className="text-green font-medium text-base truncate mt-2"
                    >
                      {item.attributes?.name}
                    </Link>
                  </div>
                  <div className="md:w-3/12 flex items-center gap-1">
                    <button
                      onClick={() => updateCartHandler(item, item.quantity - 1)}
                      className="bg-lightgray p-1.5 rounded-lg font-medium"
                      disabled={item.quantity === 1}
                    >
                      <FaMinusCircle />
                    </button>{" "}
                    <span>{item.quantity}</span>{" "}
                    <button
                      onClick={() => updateCartHandler(item, item.quantity + 1)}
                      className="bg-lightgray p-1.5 rounded-lg font-medium"
                      disabled={item.quantity === item.attributes?.countInStock}
                    >
                      <FaPlusCircle />
                    </button>
                  </div>
                  <div className="md:w-3/12">${item.attributes?.price}</div>
                  <div className="md:w-2/12">
                    <button
                      onClick={() => removeItemHandler(item)}
                      className="bg-lightgray p-1.5 rounded-lg font-medium"
                    >
                      <FaTrash />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="md:w-4/12 bg-white rounded-lg">
          <div className="p-4">
            <h3 className="text-lg font-bold pb-3 mb-2 border-lightgray border-b-4">
              Subtotal ({cartItems.reduce((a, c) => a + c.quantity, 0)} items) :
              ${cartItems.reduce((a, c) => a + c.attributes?.price * c.quantity, 0).toFixed(2)}
            </h3>
            <div className="grid grid-cols-1">
              <button
                type="button"
                className="bg-green text-white font-medium py-2 px-4 rounded-md disabled:bg-gray disabled:cursor-not-allowed"
                onClick={checkoutHandler}
                disabled={cartItems.length === 0}
              >
                Proceed to Payment
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default CartScreen;
