import React from 'react';
import boxa1 from "../../assets/Headers/SVG/Asset_2.svg";
import boxa2 from "../../assets/Headers/SVG/Asset_3.svg";
import { motion } from "framer-motion";
import partnerlogo1 from "../../assets/partners logo/HOLY ARCHDIOCESE OF AKSUM.png";
import partnerlogo2 from "../../assets/partners logo/Lion Club partner.png";
import partnerlogo3 from "../../assets/partners logo/jouri labs PARTNER.png";
import partnerlogo4 from "../../assets/partners logo/Psyann.png";

const Partners = () => {
  return (
    <div className="w-full">
      <div className="md:w-10/12 w-11/12 m-auto flex flex-col gap-1 items-center text-center py-5">
        <div className="flex mt-10 z-10 flex-col relative text-center">
        <motion.div className="flex mt-0 z-10 flex-col w-[25%] h-[8%]" >
            <motion.img
              initial={{
                x: 50,
                y: 50,
                opacity: 0,
              }}
              whileInView={{
                x: 0,
                y: 0,
                opacity: 1,
                transition: {
                  duration: 0.7,
                  type: "easy",
                },
              }}
              className="absolute bottom-[30%] left-[-20%] -z-10 h-[65%] "
              alt="box"
              src={boxa2}
            />
            <motion.img
              initial={{
                x: -50,
                y: -50,
                opacity: 0,
              }}
              whileInView={{
                x: 0,
                y: 0,
                opacity: 1,
                transition: {
                  duration: 0.7,
                  type: "easy",
                },
              }}
              className="absolute bottom-[60%] left-[-24%] -z-10 h-[65%] "
              alt="box"
              src={boxa2}
            />
            <motion.img
              initial={{
                x: -50,
                y: 50,
                opacity: 0,
              }}
              whileInView={{
                x: 0,
                y: 0,
                opacity: 1,
                transition: {
                  duration: 0.7,
                  type: "easy",
                },
              }}
              className="absolute bottom-[10%] left-[-30.5%] -z-10 h-[65%]"
              alt="box"
              src={boxa1}
            />
          </motion.div>
          <p className="lg:text-[3.5rem] md:text-[2rem] text-[2rem] text-greenshade" style={{ opacity: 1, transform: 'none' }}>
            Our Partners
          </p>
        </div>
        <div>
          <p className="lg:text-[1.5rem] md:text-[1rem] text-[1rem] text-green mb-3 md:mb-5 capitalize" style={{ transform: 'none' }}>
            This are the Partners that help us
          </p>
        </div>
        <div className="w-full flex overflow-y-auto">
      <div className="md:w-10/12 w-11/12 m-auto flex flex-col gap-1 items-center text-center py-5">
        <div className="flex w-full max-h-[20rem] gap-3 md:flex-nowrap flex-wrap md:gap-10 mb-[4rem] items-center justify-center">
          <div className="flex hover:scale-110 transition-all ease-in-out duration-300 flex-col items-center">
            <div className="md:h-[7rem] md:w-[7rem] xl:h-[12rem] xl:w-[12rem] sm:w-[5rem] sm:h-[5rem] overflow-hidden flex-none flex justify-center items-center" style={{ transform: 'none' }}>
              <img src={partnerlogo1} alt="logo" className="w-full h-full" />
            </div>

          </div>
          <div className="flex hover:scale-110 transition-all ease-in-out duration-300 flex-col items-center">
            <div className="md:h-[7rem] md:w-[7rem] xl:h-[12rem] xl:w-[12rem] sm:w-[5rem] sm:h-[5rem] overflow-hidden flex-none flex justify-center items-center" style={{ transform: 'none' }}>
              <img src={partnerlogo2} alt="logo" className="w-full h-full" />
            </div>

          </div>
          <div className="flex hover:scale-110 transition-all ease-in-out duration-300 flex-col items-center">
            <div className="md:h-[7rem] md:w-[7rem] xl:h-[12rem] xl:w-[12rem] sm:w-[5rem] sm:h-[5rem] overflow-hidden flex-none flex justify-center items-center" style={{ transform: 'none' }}>
              <img src={partnerlogo3} alt="logo" className="w-full h-full" />
            </div>

          </div>
          <div className="flex hover:scale-110 transition-all ease-in-out duration-300 flex-col items-center">
            <div className="md:h-[7rem] md:w-[7rem] xl:h-[12rem] xl:w-[12rem] sm:w-[5rem] sm:h-[5rem] overflow-hidden flex-none flex justify-center items-center" style={{ transform: 'none' }}>
              <img src={partnerlogo4} alt="logo" className="w-full h-full" />
            </div>

          </div>
          {/* Repeat the structure for other partner elements */}
          {/* ... */}
        </div>
      </div>
    </div>
      </div>
    </div>
  );
};

export default Partners;
