import CampaignHeader from "../../assets/Constantine Campaigns.png";

const DetailHead = () => {
  return (
    <div className="relative lg:h-[45rem] md:h-[34.5rem] h-[18rem] w-full overflow-hidden flex items-end">
      <img
        className="absolute inset-0 -z-10  w-full object-cover object-right md:object-center"
        src={CampaignHeader}
        alt="main "
      />
    </div>
  );
};

export default DetailHead;
