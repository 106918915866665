import * as React from "react";
import temporary from "../assets/Constantine Header Coming Soon - replace Other Services.png";

const Services = () => {
  return (
    <>
        <img
      alt="header"
      src={temporary}
      className="object-center w-full overflow-hidden"
    />
    </>
  );
};

export default Services;
