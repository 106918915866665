//import React, { useState, useEffect } from "react";
import React from "react";
import HeaderBg from "../../assets/Constantine contact Header.png";
//import { motion } from "framer-motion";

const Header = () => {
  return (
    <div
      className="relative mb-5 lg:h-[50rem] md:h-[25rem] h-[22rem] w-full overflow-hidden flex items-end"
      id="header"
    >
      <img
        src={HeaderBg}
        alt="headerimage"
        className="absolute inset-0 -z-10  w-full object-cover object-right md:object-center"
      />
    </div>
  );
};

export default Header;
