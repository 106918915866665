import { put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  getAllCampaignsAction,
  getAllCampaignsFailureAction,
  getAllCampaignsSuccessAction,
} from "../slices/getCampaingsSlice";

function* getAllCampaigns() {
  try {
    yield put(getAllCampaignsAction());
    const response = yield axios.get("/campaigns");
    yield put(getAllCampaignsSuccessAction(response.data));
  } catch (error) {
    yield put(getAllCampaignsFailureAction(error));
  }
}

export function* watchGetAllCampaigns() {
  yield takeLatest("GET_ALL_CAMPAIGNS", getAllCampaigns);
}
