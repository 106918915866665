import axios from "axios";
import { put, takeLatest } from "redux-saga/effects";
import {
  getCampaignAction,
  getCampaignSuccessAction,
  getCampaignErrorAction,
} from "../slices/campaignSlice";

function* getCampaignSaga({ id }) {
  try {
    yield put(getCampaignAction());
    const response = yield axios.get(`/campaigns/${id}`);
    yield put(getCampaignSuccessAction(response.data));
  } catch (error) {
    yield put(getCampaignErrorAction(error));
  }
}

export function* watchGetCampaignSaga() {
  yield takeLatest("GET_CAMPAIGN_BY_ID", getCampaignSaga);
}
