import { put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  deleteCampaignAction,
  deleteCampaignFailureAction,
  deleteCampaignSuccessAction,
} from "../slices/campaignSlice";

function* deleteCampaignSaga() {
  try {
    yield put(deleteCampaignAction());
    const response = yield axios.delete("");
    yield put(deleteCampaignSuccessAction(response.data));
  } catch (error) {
    yield put(deleteCampaignFailureAction(error));
  }
}

export function* watchDeleteCampaignSaga() {
  yield takeLatest("DELETE_CAMPAIGN_BY_ID", deleteCampaignSaga);
}
