import axios from "axios";
import { put, takeLatest } from "redux-saga/effects";
import {
  createCampaignAction,
  createCampaignFailureAction,
  createCampaignSuccessAction,
} from "../slices/campaignSlice";

function* createCampaignSaga({ payload }) {
  try {
    yield put(createCampaignAction());
    const response = yield axios.post("");
    yield put(createCampaignSuccessAction(response.data));
  } catch (error) {
    yield put(createCampaignFailureAction(error));
  }
}

export function* watchCreateCampaignSaga() {
  yield takeLatest("CREATE_CAMPAIGN", createCampaignSaga);
}
