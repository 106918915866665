import React, { useEffect, useState } from "react";
import axios from "axios";
import { url } from "../../data";

export default function ProjectReport() {
  // const [projects, setProjects] = useState([]);

  // useEffect(() => {
  //   axios
  //     .get("/projects")
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setProjects(res.data);
  //       }
  //     })
  //     .catch((e) => console.log(e));
  // }, []);

  // // Check if projects is an array before using forEach
  // if (!Array.isArray(projects)) {
  //   console.error("Projects data is not an array:", projects);
  //   return null; // or handle the error in a way that makes sense for your application
  // }

  // const projectReport = projects
  //   .filter((project) => project.status === 100)
  //   .map((project, index) => (
  //     <li
  //       key={index}
  //       className="flex items-center justify-center md:justify-start my-[0.5rem]"
  //     >
  //       <div className="bg-customLightGreen rounded-full h-12 w-12 md:h-16 md:w-16 mr-8 md:mr-8">
  //         <img
  //           src={project.imageURL ? `${url}${project.imageURL}` : ""}
  //           alt=""
  //         />
  //       </div>
  //       <div className="mr-8 md:mr-8">
  //         <h1 className="text-lg md:text-xl">{project.name}</h1>
  //         <h4 className="text-base md:text-lg">
  //           {project.description.split("\n")[0]}
  //         </h4>
  //       </div>
  //       <a
  //         href="/assets/downloadIcon.png"
  //         download="downloadIcon"
  //         className="ml-16"
  //       >
  //         <div className="ml-auto">
  //           <img src="/assets/downloadIcon.png" alt="" />
  //         </div>
  //       </a>
  //     </li>
  //   ));

  return (
    <div className="bg-darkgren text-white">
      {/* {projectReport.length > 0 && ( */}
        <div className="flex flex-col items-center">
          <div className="text-center mt-8 md:mt-12">
            <h1 className="text-3xl md:text-4xl lg:text-5xl text-customDarkGreen font-bold">
              Reports
            </h1>
            <h5 className="text-base md:text-lg text-customLightGreen">
              All the documentation of finished projects
            </h5>
          </div>
          {/* <ul className="w-11/12 md:w-4/5 lg:w-3/4 xl:w-2/3 mt-8 mb-12 h-[10rem] overflow-y-scroll scrollbar-thin scrollbarthumb-green-500 scrollbar-track-gray-200">
            {projectReport}
          </ul> */}
        </div><br/><br/><br/>
      {/* )} */}
    </div>
  );
}
