import axios from "axios";
import { put, takeLatest } from "redux-saga/effects";
import { getBlogAction, getBlogSuccessAction } from "../slices/slice";
import { getAllBlogsFailureAction } from "../slices/getBlogsSlice";

function* getBlogSaga({ id }) {
  try {
    yield put(getBlogAction());
    const response = yield axios.get(`/blogs/${id}`);
    yield put(getBlogSuccessAction(response.data));
  } catch (error) {
    yield put(getAllBlogsFailureAction(error));
  }
}

export function* watchGetBlogSaga() {
  yield takeLatest("GET_BLOG_BY_ID", getBlogSaga);
}
