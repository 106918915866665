import React from 'react';
import bannerimage from '../../../src/assets/volunteer Joing us.png';
import { useState } from "react";
import Modal from "../../components/VolunteerPopup"

function JoinUsBanner() {
  const [open, setOpen] = useState(false)
  return (
    <div className="font-roboto w-[90%] lg:w-[80%] m-auto my-20 border-2 border-lightgray shadow-xl p-10">
      <div className="flex md:flex-row-reverse flex-col-reverse items-center gap-5">
        <div className="w-full">
          <div className="text-[1rem] md:text-[1.4rem] xl:text-[1.9rem] font-bold">
            JOIN US AND BRING JOY!
          </div>
          <div className="text-[0.7rem] xl:text-[0.9rem] font-light text-gray">
            Volunteering is voluntary provision of services without the motivation of material reward, for the benefit of society. Volunteering is not mandatory, i.e. it is based on the voluntary participation of the active citizen. However, a volunteer is also defined as someone who contributes material goods in situations where these are considered necessary to meet human needs, without the expectation of anything in return. The aim of a volunteer is to improve the social, economic, environmental, sports, educational and other fundamental needs of a society. Our Organization, participating in the actions to promote and strengthen all kinds of volunteering in Africa, is going to organize an informative open event to find new members, who will contribute to our struggle and support our projects, in their spare time, in whatever field they choose. Help us give joy and hope!
          </div>
          <div className="flex justify-center mt-5 text-center">
            {/* <div className="rounded-xl bg-buttongreen md:text-[1rem] text-[0.7rem] px-3 py-1 text-white cursor-pointer hover:scale-105">
              Be Volunteer
            </div> */}
                                    <main className="App">
           <button className="rounded-xl bg-darkgren md:text-[1rem] text-[0.7rem] px-3 py-1 text-white cursor-pointer hover:scale-105" onClick={() => setOpen(true)}>
              Be Volunteer
           </button>
              <Modal open={open} onClose={() => setOpen(false)}>
                <div className="text-center w-96">
                    <div className="mx-auto my-4 w-96">
                      <form action="#">
                        <label htmlFor="" className="block text-darkgren text-sm font-bold mb-2 text-left">First Name:</label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-darkgren leading-tight focus:outline-none focus:shadow-outline" type="text" name="" id="" /> <br />
                        <label htmlFor="" className="block text-darkgren text-sm font-bold mb-2 text-left">Last Name:</label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-darkgren leading-tight focus:outline-none focus:shadow-outline" type="text" name="" id="" /><br />
                        <label htmlFor="" className="block text-darkgren text-sm font-bold mb-2 text-left">Email:</label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-darkgren leading-tight focus:outline-none focus:shadow-outline" type="text" name="" id="" /><br />
                        <label htmlFor="" className="block text-darkgren text-sm font-bold mb-2 text-left">CV:</label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-darkgren leading-tight focus:outline-none focus:shadow-outline" type="file" name="" id="" /><br /><br />
                        <button className="bg-transparent hover:bg-darkgren text-darkgren font-semibold hover:text-white py-2 px-4 border border-darkgren hover:border-transparent rounded">Submit</button>
                      </form>
                    </div>
                  <div className="flex gap-4">
                    <button
                      className="bg-transparent hover:bg-darkgren text-darkgren font-semibold hover:text-white py-2 px-4 border border-darkgren hover:border-transparent rounded"
                      onClick={() => setOpen(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Modal>
            </main>
          </div>
        </div>
        <div className="xl:w-[20rem] xl:h-[20rem] lg:w-[16rem] lg:h-[16rem] md:w-[14rem] md:h-[14rem] w-[10rem] h-[10rem] flex-none overflow-hidden">
          <img 
            alt="banerimage" 
            src={bannerimage}
            className="object-contain h-full w-full" />
        </div>
      </div>
    </div>
  );
}

export default JoinUsBanner;
