const AuthReduce = (state = true, action) => {
  switch (action.type) {
    case "LOGIN":
      return true;
    case "LogOut":
      return false;
    default:
      return state;
  }
};

export default AuthReduce;
