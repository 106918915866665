import React from "react";
import asset1 from "../../assets/COnstantine Be Ambassador Headere.png";
import BecomeAMember from "./BecomeAMember";
import Ambasador from '../../components/volunteer';

const  BecomeAmbasador = () =>{


    // console.log("become ambasador")
    return(
    <div>
                <div
                    className="relative h-[55rem] w-full overflow-hidden flex items-end"
                    id="header"
                >
                <img
                    src={asset1}
                    alt="header"
                    className="absolute inset-0 -z-10  w-full object-cover object-right md:object-center"
                />
                </div> 
                <div className="px-28 pt-28 text-darkgren">
                    <h1 className="text-5xl text-center">Become an Ambassador</h1>
                    <div className=" px-20 py-10">
                    <p>
                    Join the Constantine African Foundation as an Ambassador and amplify the power of your connections! 
                    Embrace the opportunity to become a catalyst for positive change. 
                    </p>
                    <p>
                    Our Ambassadors are not just volunteers; they are dynamic individuals infused with passion, 
                    dedicating their skills and time to create a lasting impact.
                    </p>
                    <p>
                    By joining us, you'll be at the forefront of spreading awareness about our impactful initiatives. 
                    As an Ambassador, you'll play a pivotal role in fostering meaningful partnerships with businesses and 
                    corporate sponsors, showcasing the true value of your network.
                    </p>
                    <p>
                    Step into a realm where your influence transforms lives, where your connections contribute to a brighter future. 
                    </p>                        
                    </div>
                    <strong className="text-2xl p-28">Be a part of something Extraordinary, Be a Constantine African Foundation Ambassador today!</strong>
                    <h1 className="px-20">What are my responsibilities as an ambassador?</h1>
                    <p className="px-20 py-3">
                    As a Constantine African Foundation Ambassador, your role is nothing short of transformative! Dive into impactful 
                    responsibilities that not only leverage your skills but also demonstrate the immense value of your connections:
                    </p>
                    <ol className=" px-40">
                        <li>
                            <b>1. Community Catalyst:</b> Immerse yourself in the local community as a research agent, delving into data collection to identify needs and 
                        formulate innovative ideas for projects aimed at reducing unemployment.
                        </li>
                        <li>
                        <b>2. Fundraising Maestro:</b> Unleash your creativity in spotting and cultivating potential fundraising opportunities both locally and 
                        internationally, ensuring a continuous influx of support for our vital initiatives.
                        </li>
                        <li>
                        <b>3. Strategic Connector:</b> Act as a liaison, distributing promotional materials to interested NGOs and individuals, 
                        facilitating the sale of our meticulously crafted products that champion our cause.
                        </li>
                        <li>
                        <b>4. Event Virtuoso: </b>Take the lead in organizing fundraising events that not only captivate but also inspire others to follow suit. 
                        Your role extends to promoting our programs and amplifying our reach and impact.
                        </li>
                        <li>
                        <b>5. Social Media Maven:</b> Spread the word far and wide! Share our mission across various social media platforms, 
                        contributing to the digital wave that propels our cause to new heights.
                        </li>
                        <li>
                        <b>6. Educational Trailblazer:</b> Become an advocate for change by educating individuals, businesses, 
                        and communities about the Constantine Foundation's Goals and Sustainable Development Objectives. 
                        Your talks and presentations will be the catalyst for awareness and action.
                        </li>
                    </ol>
                    <p className="px-20 py-10">
                    Join Constantine African Foundation as an Ambassador and elevate your influence to create a profound global impact. 
                    Embrace a transformative journey where your connections become catalysts for positive change. Your role transcends tasks; 
                    it's a dynamic showcase of your network's value.
                    </p>
                    <strong className="text-2xl text-center ml-96">Join us in creating positive change!</strong><br />
                    <strong className="text-2xl text-center ml-96">Empower change with us!</strong>
                    <h1 className="text-5xl text-center pt-10">Why Join Our Team?</h1>
                    <div className=" px-40 py-5">
                    <p className="py-3">
                    Join our dynamic team at Constantine African Foundation and be a driving force for positive change! 
                    </p>
                    <p className=" py-3">
                    As an Ambassador, you'll play a pivotal role in creating impactful job opportunities through our visionary projects. 
                    </p>
                    <p className=" py-3">
                    Become a passionate advocate for the excellence of our donation-funded initiatives. 
                    </p>
                    <p className=" py-3">
                    If you're committed to realizing dreams and championing meaningful employment, this is your 
                    platform to make a difference. 
                    </p>                        
                    </div>
                    <h1 className=" text-2xl text-center">Join us on this exciting, journey towards a brighter future!</h1>
                </div>
                <h2 className=" pl-30 text-center pb-10">Please complete the form below to apply to become Constantine African Foundation Ambassador, </h2>
                <BecomeAMember />
                {/* <Ambasador /> */}

    </div>

    )
}
export default BecomeAmbasador