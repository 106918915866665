import Lottie from "lottie-react";
import React, { useEffect, useRef } from "react";
import FinshedProjects from "../../assets/AnimationIcon/FinshedProjects.json";
import ProjectUnderWay from "../../assets/AnimationIcon/ProjectUnderWay.json";
import FinisalReport from "../../assets/AnimationIcon/FinisalReport.json";
import { motion } from "framer-motion";
import { useState } from "react";
import { Link } from "react-router-dom";
import boxr1 from "../../assets/Headers/SVG/Asset_2.svg";
import boxr2 from "../../assets/Headers/SVG/Asset_3.svg";

const Projects = () => {
  const [OnVew, setOnVew] = useState(false);

  const FinshedProjectsLottie = useRef();
  const ProjectUnderWayLottie = useRef();
  const FinisalReportLottie = useRef();
  useEffect(() => {
    if (FinshedProjectsLottie.current) {
      FinshedProjectsLottie.current.goToAndStop(100, true);
    }
    if (ProjectUnderWayLottie.current) {
      ProjectUnderWayLottie.current.goToAndStop(100, true);
    }
    if (FinisalReportLottie.current) {
      FinisalReportLottie.current.goToAndStop(60, true);
    }
  });
  return (
    <div className="flex mt-0 z-10 flex-col relative text-center">
    <motion.div
      onViewportEnter={() => {
        setOnVew(true);
      }}
      className="w-full relative overflow-hidden"
    >
      <motion.div
        animate={{
          x: OnVew ? 0 : 1300,
          transition: {
            type: "easy",
            bounce: 0.4,
            duration: 1.5,
            delay: 0.5,
          },
        }}
        className=" absolute h-full w-full   bg-gradient-to-b from-greenlight to-greendark -z-10"
      >
      </motion.div>
      <div className="md:w-4/5 w-11/12 m-auto flex flex-col gap-1 items-left text-center py-5 text-white">
      <div className="flex mt-0 z-10 flex-col relative text-center">
      <motion.div className="flex mt-0 z-10 flex-col md:w-[25%] md:h-[8%] w-[25%] h-[8%]">
  {/* First motion image */}
  <motion.img
    initial={{
      x: 50,
      y: 50,
      opacity: 0,
    }}
    whileInView={{
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.7,
        type: "easy",
      },
    }}
    className="absolute lg:bottom-[60%] lg:left-[21.5%] lg:h-[50%] md:bottom-[60%] md:left-[21.5%] -z-10 md:h-[50%] bottom-[60%] left-[15.8%] h-[30%] "
    alt="box"
    src={boxr2}
  />
  
  {/* Second motion image */}
  <motion.img
    initial={{
      x: -50,
      y: -50,
      opacity: 0,
    }}
    whileInView={{
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.7,
        type: "easy",
      },
    }}
    className="absolute lg:bottom-[40%] lg:left-[23%] lg:h-[50%] md:bottom-[40%] md:left-[23%] -z-10 md:h-[50%] bottom-[50%] left-[18%] h-[30%]"
    alt="box"
    src={boxr2}
  />
  
  {/* Third motion image */}
  <motion.img
    initial={{
      x: -50,
      y: 50,
      opacity: 0,
    }}
    whileInView={{
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.7,
        type: "easy",
      },
    }}
    className="absolute md:bottom-[26%] md:left-[19.5%] -z-10 md:h-[50%] lg:bottom-[26%] lg:left-[19.8%] lg:h-[50%] bottom-[40%] left-[13.2%] h-[30%]"
    alt="box"
    src={boxr1}
  />
</motion.div>
{/* </div> */}

        <motion.p
          initial={{
            y: 0,
            scale: 0,
            opacity: 0,
          }}
          whileInView={{
            y: 0,
            scale: 1,
            opacity: 1,
            transition: {
              duration: 0.8,
              bounce: 1,
              type: "easy",
              delay: 1,
            },
          }}
          viewport={{ once: true }}
          className="lg:text-[3.5rem] md:text-[2rem] text-[2rem]"
        >
          Report
        </motion.p>
        <motion.p
          initial={{
            y: 10,
            scale: 0,
            opacity: 0,
          }}
          whileInView={{
            y: 0,
            scale: 1,
            opacity: 1,
            transition: {
              duration: 0.8,
              bounce: 1,
              type: "easy",
              delay: 1,
            },
          }}
          viewport={{ once: true }}
          className="lg:text-[1.5rem] md:text-[1rem] text-[1rem] mb-5"
        >
          Be a part of what we achived so far
        </motion.p>
        </div>
        <div className=" flex w-full gap-5 flex-wrap sm:flex-nowrap md:gap-20 mb-[1rem] items-center">
          <div className=" relative flex w-full items-center flex-wrap md:flex-nowrap  flex-col gap-1 md:gap-3 ">
            <motion.div
              initial={{
                y: 0,
                scale: 0,
                opacity: 0,
              }}
              whileInView={{
                y: 0,
                scale: 1,
                opacity: 1,
                transition: {
                  duration: 0.8,
                  bounce: 1,
                  type: "easy",
                  delay: 1,
                },
              }}
              viewport={{ once: true }}
              className="h-[7rem] w-[7rem] md:h-[12rem] md:w-[12rem] bg-white rounded-full  "
              onMouseEnter={() => {
                FinshedProjectsLottie.current.goToAndPlay(4, true);
              }}
              onMouseLeave={() => {
                FinshedProjectsLottie.current.goToAndStop(100, true);
              }}
            >
              <Lottie
                animationData={FinshedProjects}
                loop={true}
                autoplay={false}
                lottieRef={FinshedProjectsLottie}
              />
            </motion.div>
            <motion.p
              initial={{
                y: 10,
                scale: 0,
                opacity: 0,
              }}
              whileInView={{
                y: 0,
                scale: 1,
                opacity: 1,
                transition: {
                  duration: 0.8,
                  bounce: 1,
                  type: "easy",
                },
              }}
              viewport={{ once: true }}
              className="text-[1.2rem] md:text-[1.9rem] md:mb-5 mb-2"
            >
              Finshed Projects
            </motion.p>
            <motion.p
              initial={{
                y: 10,
                scale: 0,
                opacity: 0,
              }}
              whileInView={{
                y: 0,
                scale: 1,
                opacity: 1,
                transition: {
                  duration: 0.8,
                  bounce: 1,
                  type: "easy",
                },
              }}
              viewport={{ once: true }}
              className=" text-[.7rem] md:text-[.9rem] mb-5 font-sans"
            >
              our completed projects stand as a testament to our mission and the
              positive impact we've made. From education initiatives to
              healthcare, clean water, disaster relief, and empowerment programs
            </motion.p>
            <motion.div
              initial={{
                y: 10,
                scale: 0,
                opacity: 0,
              }}
              whileInView={{
                y: 0,
                scale: 1,
                opacity: 1,
                transition: {
                  duration: 0.8,
                  bounce: 1,
                  type: "easy",
                },
              }}
              viewport={{ once: true }}
              className="w-full flex justify-center text-white font-bold  "
            >
              <Link to={`/projects`} onClick={() => window.scrollTo(0, 0)}>
                <div className="font-sans bg-buttongreen rounded-3xl mb-4 px-2 py-1 text-sm ">
                  Read More
                </div>
              </Link>
            </motion.div>
          </div>
          <div className=" relative flex w-full items-center  flex-col gap-3 ">
            <motion.div
              initial={{
                y: 0,
                scale: 0,
                opacity: 0,
              }}
              viewport={{ once: true }}
              whileInView={{
                y: 0,
                scale: 1,
                opacity: 1,
                transition: {
                  duration: 0.8,
                  bounce: 1,
                  type: "easy",
                  delay: 1,
                },
              }}
              className="h-[7rem] w-[7rem] md:h-[12rem] md:w-[12rem] bg-white rounded-full  "
              onMouseEnter={() => {
                ProjectUnderWayLottie.current.goToAndPlay(4, true);
              }}
              onMouseLeave={() => {
                ProjectUnderWayLottie.current.goToAndStop(100, true);
              }}
            >
              <Lottie
                animationData={ProjectUnderWay}
                loop={true}
                autoplay={false}
                lottieRef={ProjectUnderWayLottie}
              />
            </motion.div>
            <motion.p
              initial={{
                y: 10,
                scale: 0,
                opacity: 0,
              }}
              viewport={{ once: true }}
              whileInView={{
                y: 0,
                scale: 1,
                opacity: 1,
                transition: {
                  duration: 0.8,
                  bounce: 1,
                  type: "easy",
                },
              }}
              className=" text-[1.2rem] md:text-[1.9rem] mb-5"
            >
              Project UnderWay
            </motion.p>
            <motion.p
              initial={{
                y: 10,
                scale: 0,
                opacity: 0,
              }}
              viewport={{ once: true }}
              whileInView={{
                y: 0,
                scale: 1,
                opacity: 1,
                transition: {
                  duration: 0.8,
                  bounce: 1,
                  type: "easy",
                },
              }}
              className=" text-[.7rem] md:text-[.9rem] mb-5 font-sans"
            >
              our ongoing projects are the engines of transformation. They
              embody our dedication to creating a brighter future and
              demonstrate that our work never stops
            </motion.p>
            <motion.div
              initial={{
                y: 10,
                scale: 0,
                opacity: 0,
              }}
              viewport={{ once: true }}
              whileInView={{
                y: 0,
                scale: 1,
                opacity: 1,
                transition: {
                  duration: 0.8,
                  bounce: 1,
                  type: "easy",
                },
              }}
              className="w-full flex justify-center text-white font-bold  "
            >
              <Link onClick={() => window.scrollTo(0, 0)} to={`/projects`}>
                <div className="font-sans bg-buttongreen rounded-3xl mb-4 px-2 py-1 text-sm ">
                  Read More
                </div>
              </Link>
            </motion.div>
          </div>
          <div className=" relative flex w-full items-center  flex-col gap-3 ">
            <motion.div
              initial={{
                y: 0,
                scale: 0,
                opacity: 0,
              }}
              viewport={{ once: true }}
              whileInView={{
                y: 0,
                scale: 1,
                opacity: 1,
                transition: {
                  duration: 0.8,
                  bounce: 1,
                  type: "easy",
                  delay: 1,
                },
              }}
              className="h-[7rem] w-[7rem] md:h-[12rem] md:w-[12rem] bg-white rounded-full  "
              onMouseEnter={() => {
                FinisalReportLottie.current.goToAndPlay(0, true);
              }}
              onMouseLeave={() => {
                FinisalReportLottie.current.goToAndStop(60, true);
              }}
            >
              <Lottie
                animationData={FinisalReport}
                loop={true}
                autoplay={false}
                lottieRef={FinisalReportLottie}
              />
            </motion.div>
            <motion.p
              initial={{
                y: 10,
                scale: 0,
                opacity: 0,
              }}
              viewport={{ once: true }}
              whileInView={{
                y: 0,
                scale: 1,
                opacity: 1,
                transition: {
                  duration: 0.8,
                  bounce: 1,
                  type: "easy",
                },
              }}
              className=" text-[1.2rem] md:text-[1.9rem] mb-5"
            >
              Financial Report
            </motion.p>
            <motion.p
              initial={{
                y: 10,
                scale: 0,
                opacity: 0,
              }}
              viewport={{ once: true }}
              whileInView={{
                y: 0,
                scale: 1,
                opacity: 1,
                transition: {
                  duration: 0.8,
                  bounce: 1,
                  type: "easy",
                },
              }}
              className=" text-[.7rem] md:text-[.9rem] mb-5 font-sans"
            >
              This report provides a summary of our financial activities,
              including income, expenses, and the allocation of funds to various
              projects and programs. We are committed to maintaining
              transparency and accountability in our financial operations.
            </motion.p>
            <motion.div
              initial={{
                y: 10,
                scale: 0,
                opacity: 0,
              }}
              viewport={{ once: true }}
              whileInView={{
                y: 0,
                scale: 1,
                opacity: 1,
                transition: {
                  duration: 0.8,
                  bounce: 1,
                  type: "easy",
                },
              }}
              className="w-full flex justify-center text-white font-bold  "
            >
              <Link onClick={() => window.scrollTo(0, 0)} to={`/projects`}>
                <div className="font-sans bg-buttongreen rounded-3xl mb-4 px-2 py-1 text-sm ">
                  Read More
                </div>
              </Link>
            </motion.div>
          </div>
        </div>
      </div>
    </motion.div>
    </div>  
  );
};

export default Projects;
